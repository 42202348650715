import { useEffect, useState } from 'react'
import { VotesCardBig } from './voteCardBig'
import Rules from 'assets/images/content/votes/rules.png'
import Polozhenie from 'assets/pdf/polozhenie-o-konkurse-vkgi.pdf'

import { Loader } from 'components/loader/loader'
import axios from 'axios'
import { Link } from 'react-router-dom'
import './style.scss'

export const MainVotes = () => {
  const [votes, setVotes] = useState([])
  const [votesActive, setVotesActive] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getVotes()
  }, [])

  const getVotes = (nomination = null) => {
    var getString = '?'
    getString += nomination ? 'nomination_id=' + nomination : ''
    axios
      .get(`${process.env.REACT_APP_BACKEND}/application-main${getString}`)
      .then((data) => {
        setVotes(data.data)
        setLoading(false)
      })
  }

  const renderBlocks = () => {
    if (!loading && votes.data && votes.data.length > 0) {
      return votes.data.map((voteItem, index) => {
        return index <= 2 && <VotesCardBig key={voteItem.id} votes={voteItem} />
      })
    }
    if (!loading && (!votes.data || votes.data.length < 1)) {
      return (
        <div className="votes__empty">
          <span>
            Идет процесс подачи заявок на конкурс. Заявки будут опубликованы на
            сайте после их рассмотрения.
          </span>
        </div>
      )
    }
  }

  return (
    <section id="votes" className="votes">
      <div className="container">
        <div className="votes__wrap">
          <div className="votes__left">
            <div className="votes__left_top">
              <div className="votes__title title">Поданные заявки</div>
              <div className="votes__menu">
                <div
                  onClick={() => {
                    getVotes(votesActive === 1 ? null : 1)
                    setVotesActive(votesActive === 1 ? 0 : 1)
                  }}
                  className={
                    'votes__menu_item ' + (votesActive === 1 ? 'active' : '')
                  }
                >
                  Лучшая НКО
                </div>
                <div
                  onClick={() => {
                    getVotes(votesActive === 2 ? null : 2)
                    setVotesActive(votesActive === 2 ? 0 : 2)
                  }}
                  className={
                    'votes__menu_item ' + (votesActive === 2 ? 'active' : '')
                  }
                >
                  Лучший проект
                </div>
                <div
                  onClick={() => {
                    getVotes(votesActive === 3 ? null : 3)
                    setVotesActive(votesActive === 3 ? 0 : 3)
                  }}
                  className={
                    'votes__menu_item ' + (votesActive === 3 ? 'active' : '')
                  }
                >
                  Лучшая идея
                </div>
              </div>
            </div>
            {loading ? <Loader /> : null}
            <div className="votes__list">{renderBlocks()}</div>
            {true && (
              <Link className="votes__button" to={'/voting'}>
                Смотреть всё
              </Link>
            )}
          </div>
          <div className="votes__right">
            <div className="votes__title title">Положение о&nbsp;конкурсе</div>
            <div className="votes__rules_img">
              <img src={Rules} alt="" />
            </div>
            <a
              className="votes__button"
              href={Polozhenie}
              target="_blank"
              rel="noreferrer"
            >
              скачать файл
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
