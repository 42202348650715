import { AuthProvider } from './context';
import AppRoute from 'components/appRouter';
import { routes } from 'config/routes';

export default function App() {
	return (
		<AuthProvider>
			{routes.map((route) => (
				<AppRoute
					key={route.path}
					path={route.path}
					isPrivate={route.isPrivate}
					component={route.component}
				/>
			))}
		</AuthProvider>
	);
}
