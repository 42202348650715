import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { NavLink } from 'react-router-dom'
import IconLeftArrowWhite from 'assets/images/svg/icon_left-arrow-white.svg'
import './index.scss'
const Breadcrumbs = ({ breadcrumbs, title }) => {
  return (
    <div className="breadscrumb">
      <img src={IconLeftArrowWhite} alt={'ico'} />
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        return (
          <NavLink
            key={match.url}
            className={
              index + 1 !== breadcrumbs.length
                ? 'crumb_item crumb_first'
                : 'crumb_item'
            }
            to={match.url}
          >
            {match.url === `/voting/${breadcrumb.props.children}`
              ? title?.substring(0, 35) + '...'
              : breadcrumb}{' '}
          </NavLink>
        )
      })}
    </div>
  )
}

export default withBreadcrumbs([
  {
    path: '/',
    breadcrumb: 'Главная',
  },
  {
    path: '/profile',
    breadcrumb: 'Личный кабинет',
  },
  {
    path: '/voting',
    breadcrumb: 'Голосование',
  },
])(Breadcrumbs)
