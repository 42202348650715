import './Nominations.scss'

export const Nominations = () => {

  return (
    <section id="nominations" className="nominations">
      <div className="container">
        <h2 className="nominations__title title">Номинации</h2>
        <div className="nominations__wrap">
          <div className="nominations__item">
            <div className="nominations__item_title">Лучшая НКО</div>
            <div className="nominations__item_desc">
              Определяется лучшая НКО (всероссийская, региональная, местная) по
              работе в 2023 году. На момент подачи заявки НКО должно быть не
              менее 2 лет с момента регистрации.
            </div>
          </div>
          <div className="nominations__item">
            <div className="nominations__item_title">Лучший проект</div>
            <div className="nominations__item_desc">
              Определяются лучшие проекты, реализованные в 2023 году как
              юридическими, так и физическими лицами.
            </div>
          </div>
          <div className="nominations__item">
            <div className="nominations__item_title">Лучшая идея</div>
            <div className="nominations__item_desc">
              Определяются лучшие идеи как юридических, так и физических лиц,
              которые могут быть реализованы в 2024 году и могут повлиять на
              развитие населенного пункта, региона и страны.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
