import { Header } from './../header'
import { Footer } from './../footer'

export const SimpleLayout = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer layoutType="simple" />
    </>
  )
}
