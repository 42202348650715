import Slider from "react-slick";
import Asafov from 'assets/images/comitet/asafov.png'
import Gore from 'assets/images/comitet/gore.png'
import Ilush from 'assets/images/comitet/ilush.png'
import Karmanov from 'assets/images/comitet/karmanov.png'
import Lvova from 'assets/images/comitet/lvova.png'
import Karelin from 'assets/images/comitet/karelin.jpg'
import Maidan from 'assets/images/comitet/maidan.png'
import Sidyakin from 'assets/images/comitet/sidyakin.png'
import Terent from 'assets/images/comitet/terent.png'
import Valuev from 'assets/images/comitet/valuev.png'
import Zanko from 'assets/images/comitet/zanko.png'
import './Comitet.scss'

export const Comitet = () => {

  const BtnPrev = ({onClick}) => {
    return (
<button onClick={onClick} className="pagination__link slick-arrow slick-prev" type="button"> {'<'}</button>
    )
  }

  const BtnNext = ({onClick}) => {
    return (
<button onClick={onClick} className="pagination__link slick-arrow slick-next" type="button"> {'>'}</button>
    )
  }

  const settings = {
    infinite: true,
    speed: 500,
    // autoplaySpeed: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    buttons: false,
    // autoplay: true,
    prevArrow: <BtnPrev dir='left' />,
		nextArrow: <BtnNext dir='right' />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 465,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };
  return (
    <section className="comitet" id='comitet'>
      <div className="container">
        <h2 className="comitet__title title">Организационный комитет</h2>
        <div className="comitet__wrap">
        <Slider className="comitet__wrap" {...settings}>
          <div className="comitet__item">
            <img className="comitet__item_img" src={Karelin} alt="" />
            <div className="comitet__item_lastname">КАРЕЛИН</div>
            <div className="comitet__item_name">Александр Александрович</div>
            <div className="comitet__item_desc">
            Трёхкратный Олимпийский чемпион, сенатор РФ, член Президиума Центрального совета сторонников Партии
            </div>
          </div>
          <div className="comitet__item">
            <img className="comitet__item_img" src={Sidyakin} alt="" />
            <div className="comitet__item_lastname">СИДЯКИН</div>
            <div className="comitet__item_name">Александр Геннадьевич</div>
            <div className="comitet__item_desc">
            Заместитель Секретаря Генерального совета Партии, Руководитель ЦИК Партии, депутат Государственной Думы
            </div>
          </div>
          <div className="comitet__item">
            <img className="comitet__item_img" src={Zanko} alt="" />
            <div className="comitet__item_lastname">ЗАНКО</div>
            <div className="comitet__item_name">Ольга Николаевна</div>
            <div className="comitet__item_desc">
            Председатель Центрального совета сторонников Партии, депутат Государственной Думы
            </div>
          </div>

          <div className="comitet__item">
            <img className="comitet__item_img" src={Valuev} alt="" />
            <div className="comitet__item_lastname">ВАЛУЕВ</div>
            <div className="comitet__item_name">Николай Сергеевич</div>
            <div className="comitet__item_desc">
            Многократный чемпион мира по боксу, депутат Государственной Думы
            </div>
          </div>
          <div className="comitet__item">
            <img className="comitet__item_img" src={Maidan} alt="" />
            <div className="comitet__item_lastname">МАЙДАНОВ</div>
            <div className="comitet__item_name">Денис Васильевич</div>
            <div className="comitet__item_desc">
            Заслуженный артист РФ, депутат Государственной Думы
            </div>
          </div>
          <div className="comitet__item">
            <img className="comitet__item_img" src={Ilush} alt="" />
            <div className="comitet__item_lastname">ИЛЮШНИКОВА</div>
            <div className="comitet__item_name">Татьяна Александровна</div>
            <div className="comitet__item_desc">
            Заместитель Министра экономического развития РФ
            </div>
          </div>
          <div className="comitet__item">
            <img className="comitet__item_img" src={Karmanov} alt="" />
            <div className="comitet__item_lastname">КАРМАНОВ</div>
            <div className="comitet__item_name">Роман Владимирович</div>
            <div className="comitet__item_desc">
            Генеральный директор Президентского фонда культурных инициатив
            </div>
          </div>
          <div className="comitet__item">
            <img className="comitet__item_img" src={Lvova} alt="" />
            <div className="comitet__item_lastname">ЛЬВОВА-БЕЛОВА</div>
            <div className="comitet__item_name">Мария Алексеевна</div>
            <div className="comitet__item_desc">
            Уполномоченный при Президенте РФ по правам ребенка
            </div>
          </div>
          <div className="comitet__item">
            <img className="comitet__item_img" src={Terent} alt="" />
            <div className="comitet__item_lastname">ТЕРЕНТЬЕВ</div>
            <div className="comitet__item_name">Михаил Борисович</div>
            <div className="comitet__item_desc">
            Председатель Всероссийского общества инвалидов, депутат Государственной Думы
            </div>
          </div>
          <div className="comitet__item">
            <img className="comitet__item_img" src={Gore} alt="" />
            <div className="comitet__item_lastname">ГОРЕСЛАВСКИЙ</div>
            <div className="comitet__item_name">Алексей Сергеевич</div>
            <div className="comitet__item_desc">
            Директор Института развития Интернета
            </div>
          </div>

          <div className="comitet__item">
            <img className="comitet__item_img" src={Asafov} alt="" />
            <div className="comitet__item_lastname">АСАФОВ</div>
            <div className="comitet__item_name">Александр Николаевич</div>
            <div className="comitet__item_desc">
            Член Общественной палаты РФ
            </div>
          </div>


          </Slider>
        </div>
      </div>
    </section>
  )
}
