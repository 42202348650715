import { Link } from 'react-router-dom'
import Mock from 'assets/images/mock.png'
import Like from 'assets/images/svg/like.svg'
import LikeGrey from 'assets/images/svg/like-grey.svg'
import { useAuthState } from 'context'
import Winner from 'assets/images/winner.png'

export const VotesCardBig = ({ votes }) => {
  const { userDetails } = useAuthState()

  const image = votes?.image?.thumb ? votes.image.thumb : Mock
console.log(votes)
  return (
    <Link to={`/voting/${votes.id}`} className="votes__item">
    
      <div className="votes__item_img">
        <img src={image} alt="" />
       {votes.is_winner && <img src={Winner} alt='' width={70} className='votes__item_img winner' height={70} />} 
      </div>
      <div className="votes__item_text">
        <p className="votes__item_title">{votes.name}</p>
        <p className="votes__item_subtitle">{votes.region.name}</p>
        <p className="votes__item_descr">{votes.organisation_level?.description}</p>
        {true && (
          <span className="votes__item_like">
            {votes.count_votes ? votes.count_votes : 0}
            <img
              src={
                userDetails?.voted_nominations?.includes(votes.id)
                  ? Like
                  : LikeGrey
              }
              alt=""
            />
          </span>
        )}
      </div>
      
    </Link>
  )
}
