
import Mock from 'assets/images/mock.png'

import Tg from 'assets/images/svg/tg.svg'
import Vk from 'assets/images/svg/vk.svg'
import Ok from 'assets/images/svg/ok.svg'
import Website from 'assets/images/svg/website.svg'

const BestNko = ({ data }) => {

  return (
    <>
      <div className="text-page__left">
        <div className="text-page__description">
          <div className="text-page__description_item">
            <div className="text-page__label">
              География реализации
            </div>
            <div className="text-page__text">
              {data?.organisation_level?.description}
            </div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Регион</div>
            <div className="text-page__text">{data?.region?.name}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Полное название НКО</div>
            <div className="text-page__text">{data?.company?.name}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Ссылки на сайт и социальные сети НКО</div>
            {data?.company?.links?.Site ||
            data?.company?.links?.Telegram ||
            data?.company?.links?.SnVK ||
            data?.company?.links?.SnOK ? (
            <div className="text-page__links">
              <div className="text-page__links_wrap">
                {data?.company?.links?.Site && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.Site}
                    className="text-page__links_item"
                  >
                    <img src={Website} alt="" />
                    {data?.company?.links?.Site}
                  </a>
                )}
                {data?.company?.links?.Telegram && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.Telegram}
                    className="text-page__links_item"
                  >
                    <img src={Tg} alt="" />
                    {data?.company?.links?.Telegram}
                  </a>
                )}
                {data?.company?.links?.SnVK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.SnVK}
                    className="text-page__links_item"
                  >
                    <img src={Vk} alt="" />
                    {data?.company?.links?.SnVK}
                  </a>
                )}
                {data?.company?.links?.SnOK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.SnOK}
                    className="text-page__links_item"
                  >
                    <img src={Ok} alt="" />
                    {data?.company?.links?.SnOK}
                  </a>
                )}
              </div>
            </div>
          ) : null}
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Цели и задачи НКО</div>
            <div className="text-page__text">{data?.description}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">5 главных достижений НКО за 2023 год</div>
            {data.achievements?.length > 0 && (
              <ul style={{ paddingLeft: '20px' }}>
                {data.achievements?.map((e) => {
                  return <li style={{ listStyle: 'disc' }}>{e}</li>
                })}
              </ul>
            )


            }
          </div>
        </div>
      </div>
      <div className="text-page__right">
        <div className="text-page__right_wrap">
          <img
            src={data?.image?.path ? data.image.path : Mock}
            alt=""
          />
        </div>
      </div>
    </>
  )
}


const BestProjectPhys = ({ data }) => {

  return (
    <>
      <div className="text-page__left">
        <div className="text-page__description">
          <div className="text-page__description_item">
            <div className="text-page__label">
              География реализации
            </div>
            <div className="text-page__text">
              {data?.organisation_level?.description}
            </div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Регион</div>
            <div className="text-page__text">{data?.region?.name}</div>
          </div>

          <div className="text-page__description_item">
            <div className="text-page__label">ФИО заявителя</div>
            <div className="text-page__text">{data?.individual_fio}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Наименование проекта</div>
            <div className="text-page__text">{data?.name}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Краткое описание проекта</div>
            <div className="text-page__text">{data?.description}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Актуальность</div>
            <div className="text-page__text">{data?.actual}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">5 главных достижений НКО за 2023 год</div>
            {data.achievements?.length > 0 && (
              <ul style={{ paddingLeft: '20px' }}>
                {data.achievements?.map((e) => {
                  return <li style={{ listStyle: 'disc' }}>{e}</li>
                })}
              </ul>
            )}
          </div>

          <div className="text-page__description_item">

            <div className="text-page__label">Ссылки на проект</div>
            {data?.links?.Project?.Site ||
            data?.links?.Project?.Telegram ||
            data?.links?.Project?.SnVK ||
            data?.links?.Project?.SnOK ? (
            <div className="text-page__links">
              <div className="text-page__links_wrap">
                {data?.links?.Project?.Site && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.links?.Project?.Site}
                    className="text-page__links_item"
                  >
                    <img src={Website} alt="" />
                    {data?.links?.Project?.Site}
                  </a>
                )}
                {data?.links?.Project?.Telegram && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.links?.Project?.Telegram}
                    className="text-page__links_item"
                  >
                    <img src={Tg} alt="" />
                    {data?.links?.Project?.Telegram}
                  </a>
                )}
                {data?.links?.Project?.SnVK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.links?.Project?.SnVK}
                    className="text-page__links_item"
                  >
                    <img src={Vk} alt="" />
                    {data?.links?.Project?.SnVK}
                  </a>
                )}
                {data?.links?.Project?.SnOK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.links?.Project?.SnOK}
                    className="text-page__links_item"
                  >
                    <img src={Ok} alt="" />
                    {data?.links?.Project?.SnOK}
                  </a>
                )}
              </div>
            </div>
          ) : null}
          </div>
        </div>
      </div>
      <div className="text-page__right">
        <div className="text-page__right_wrap">
          <img
            src={data?.image?.path ? data.image.path : Mock}
            alt=""
          />
          
        </div>
      </div>
    </>
  )
}


const BestProjectComp = ({ data }) => {

  return (
    <>
      <div className="text-page__left">
        <div className="text-page__description">
          <div className="text-page__description_item">
            <div className="text-page__label">
              География реализации
            </div>
            <div className="text-page__text">
              {data?.organisation_level?.description}
            </div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Регион</div>
            <div className="text-page__text">{data?.region?.name}</div>
          </div>
          <div className="text-page__description_item">

            <div className="text-page__label">Ссылки на сайт и социальные сети НКО</div>
            {data?.company?.links?.Site ||
            data?.company?.links?.Telegram ||
            data?.company?.links?.SnVK ||
            data?.company?.links?.SnOK ? (
            <div className="text-page__links">
              <div className="text-page__links_wrap">
                {data?.company?.links?.Site && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.Site}
                    className="text-page__links_item"
                  >
                    <img src={Website} alt="" />
                    {data?.company?.links?.Site}
                  </a>
                )}
                {data?.company?.links?.Telegram && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.Telegram}
                    className="text-page__links_item"
                  >
                    <img src={Tg} alt="" />
                    {data?.company?.links?.Telegram}
                  </a>
                )}
                {data?.company?.links?.SnVK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.SnVK}
                    className="text-page__links_item"
                  >
                    <img src={Vk} alt="" />
                    {data?.company?.links?.SnVK}
                  </a>
                )}
                {data?.company?.links?.SnOK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.SnOK}
                    className="text-page__links_item"
                  >
                    <img src={Ok} alt="" />
                    {data?.company?.links?.SnOK}
                  </a>
                )}
              </div>
            </div>
          ) : null}
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Полное название НКО</div>
            <div className="text-page__text">{data?.company?.name}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Наименование проекта</div>
            <div className="text-page__text">{data?.name}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Краткое описание проекта</div>
            <div className="text-page__text">{data?.description}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Актуальность</div>
            <div className="text-page__text">{data?.actual}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">5 главных достижений НКО за 2023 год</div>
            {data.achievements?.length > 0 && (
              <ul style={{ paddingLeft: '20px' }}>
                {data.achievements?.map((e) => {
                  return <li style={{ listStyle: 'disc' }}>{e}</li>
                })}
              </ul>
            )}
          </div>
          <div className="text-page__description_item">

            <div className="text-page__label">Ссылки на проект</div>
            {data?.links?.Project?.Site ||
            data?.links?.Project?.Telegram ||
            data?.links?.Project?.SnVK ||
            data?.links?.Project?.SnOK ? (
            <div className="text-page__links">
              <div className="text-page__links_wrap">
                {data?.links?.Project?.Site && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.links?.Project?.Site}
                    className="text-page__links_item"
                  >
                    <img src={Website} alt="" />
                    {data?.links?.Project?.Site}
                  </a>
                )}
                {data?.links?.Project?.Telegram && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.links?.Project?.Telegram}
                    className="text-page__links_item"
                  >
                    <img src={Tg} alt="" />
                    {data?.links?.Project?.Telegram}
                  </a>
                )}
                {data?.links?.Project?.SnVK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.links?.Project?.SnVK}
                    className="text-page__links_item"
                  >
                    <img src={Vk} alt="" />
                    {data?.links?.Project?.SnVK}
                  </a>
                )}
                {data?.links?.Project?.SnOK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.links?.Project?.SnOK}
                    className="text-page__links_item"
                  >
                    <img src={Ok} alt="" />
                    {data?.links?.Project?.SnOK}
                  </a>
                )}
              </div>
            </div>
          ) : null}
          </div>
        </div>
      </div>
      <div className="text-page__right">
        <div className="text-page__right_wrap">
          <img
            src={data?.image?.path ? data.image.path : Mock}
            alt=""
          />
          
        </div>
      </div>
    </>
  )
}

const BestIdeaPhys = ({ data }) => {
  return (
    <>
      <div className="text-page__left">
        <div className="text-page__description">
          <div className="text-page__description_item">
            <div className="text-page__label">
              География реализации
            </div>
            <div className="text-page__text">
              {data?.organisation_level?.description}
            </div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Регион</div>
            <div className="text-page__text">{data?.region?.name}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">ФИО заявителя</div>
            <div className="text-page__text">{data?.individual_fio}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Наименование идеи</div>
            <div className="text-page__text">{data?.name}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Краткое описание идеи</div>
            <div className="text-page__text">{data?.description}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Актуальность</div>
            <div className="text-page__text">{data?.actual}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">5 главных достижений или проблем, которые решит идея</div>
            {data.achievements?.length > 0 && (
              <ul style={{ paddingLeft: '20px' }}>
                {data.achievements?.map((e) => {
                  return <li style={{ listStyle: 'disc' }}>{e}</li>
                })}
              </ul>
            )


            }
          </div>
        </div>
      </div>
      <div className="text-page__right">
        <div className="text-page__right_wrap">
          <img
            src={data?.image?.path ? data.image.path : Mock}
            alt=""
          />
        </div>
      </div>
    </>
  )
}

const BestIdeaComp = ({ data }) => {

  return (
    <>
      <div className="text-page__left">
        <div className="text-page__description">
          <div className="text-page__description_item">
            <div className="text-page__label">
              География реализации
            </div>
            <div className="text-page__text">
              {data?.organisation_level?.description}
            </div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Регион</div>
            <div className="text-page__text">{data?.region?.name}</div>
          </div>
          <div className="text-page__description_item">

            <div className="text-page__label">Ссылки на сайт и социальные сети НКО</div>
            {data?.company?.links?.Site ||
            data?.company?.links?.Telegram ||
            data?.company?.links?.SnVK ||
            data?.company?.links?.SnOK ? (
            <div className="text-page__links">
              <div className="text-page__links_wrap">
                {data?.company?.links?.Site && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.Site}
                    className="text-page__links_item"
                  >
                    <img src={Website} alt="" />
                    {data?.company?.links?.Site}
                  </a>
                )}
                {data?.company?.links?.Telegram && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.Telegram}
                    className="text-page__links_item"
                  >
                    <img src={Tg} alt="" />
                    {data?.company?.links?.Telegram}
                  </a>
                )}
                {data?.company?.links?.SnVK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.SnVK}
                    className="text-page__links_item"
                  >
                    <img src={Vk} alt="" />
                    {data?.company?.links?.SnVK}
                  </a>
                )}
                {data?.company?.links?.SnOK && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.company?.links?.SnOK}
                    className="text-page__links_item"
                  >
                    <img src={Ok} alt="" />
                    {data?.company?.links?.SnOK}
                  </a>
                )}
              </div>
            </div>
          ) : null}
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Полное название НКО</div>
            <div className="text-page__text">{data?.company?.name}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Наименование идеи</div>
            <div className="text-page__text">{data?.name}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Краткое описание идеи</div>
            <div className="text-page__text">{data?.description}</div>
          </div>
          <div className="text-page__description_item">
            <div className="text-page__label">Актуальность</div>
            <div className="text-page__text">{data?.actual}</div>
          </div>

          <div className="text-page__description_item">
            <div className="text-page__label">5 главных достижений или проблем, которые решит идея</div>
            {data.achievements?.length > 0 && (
              <ul style={{ paddingLeft: '20px' }}>
                {data.achievements?.map((e) => {
                  return <li style={{ listStyle: 'disc' }}>{e}</li>
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="text-page__right">
        <div className="text-page__right_wrap">
          <img
            src={data?.image?.path ? data.image.path : Mock}
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export { BestNko, BestProjectPhys, BestProjectComp, BestIdeaPhys, BestIdeaComp }
