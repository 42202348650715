import { Main } from 'pages/main'
import { DetailNews } from 'templates/detailedNew'
import { Login } from 'pages/login/'
import { Profile } from 'pages/profile'
import { Voiting } from 'pages/voting'
import { Vote } from 'pages/vote'
import { SendVote } from 'pages/vote/sendvote'
export const routes = [
  {
    path: '/login',
    component: Login,
    isPrivate: false,
    breadcrumb: 'Авторизация',
  },
  {
    path: '/',
    component: Main,
    isPrivate: false,
    breadcrumb: 'Главная',
  },
  {
    path: '/voting/:id',
    component: DetailNews,
    isPrivate: true,
    breadcrumb: null,
  },
  {
    path: '/profile',
    component: Profile,
    isPrivate: true,
    breadcrumb: 'Профиль',
  },
  {
    path: '/vote',
    component: Vote,
    isPrivate: false,
    breadcrumb: 'Подача заявки на конкурс',
  },
  {
    path: '/vote/sendvote',
    component: SendVote,
    isPrivate: true,
    breadcrumb: 'Подача заявки на конкурс',
  },
  {
    path: '/voting',
    component: Voiting,
    isPrivate: false,
    breadcrumb: null,
  },
]
