import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

export const VotedModal = ({
  isOpen,
  setIsOpen,
}) => {
  const clearModalState = () => {
    setIsOpen(false)
  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgb(255,255,255,0.5)',
      display: 'flex',
      alignItems: 'center',
      zIndex: 9,
    },
    content: {
      position: 'unset',
      backgroundColor: '#2173BA',
      height: 'fit-content',
      margin: '0 auto',
      top: '0',
      bottom: '0',
      transform: 'unset',
      padding: '25px',
      maxWidth: '520px',
      width: '100%',
    },
  }

  const closeModal = () => clearModalState()

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={'submit_modal'}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={false}
    >
      <div>
        <h2>Голосование</h2>
        <hr />
            <div className="sendvote__simpletext">{"Вы уже проголосовали"}</div>
            <div className="sendvote__input-button to-right">
              <button
                className="sendvote__btn-submit button"
                onClick={() => {
                  closeModal()
                }}
              >
                <span>Закрыть</span>
              </button>
            </div>
      </div>
    </Modal>
  )
}
