import { Formik, Form, Field } from 'formik'
import InputMask from 'react-input-mask'
import { TimedBtn } from 'components/timer'
import { Bckgr } from 'blocks'
import { MainLayout } from 'components/layout/mainLayout'
import { useAuthDispatch } from '../../../context'
import BreadcrumbsLogin from 'components/breadcrumbsProfile'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { loginUser } from 'context/actions'
import { errorSmsToast } from 'components/notifications'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Loader } from '../../loader/loader'
import { scroll } from 'components/utils'
const Input = (props) => (
  <InputMask
    mask="999999"
    value={props.value}
    maskChar={null}
    onChange={props.onChange}
    placeholder={'СМС-код'}
  >
    {(inputProps) => {
      return (
        <Field
          {...inputProps}
          type="text"
          autoComplete="one-time-code"
          inputMode="numeric"
          name="code"
          className={props.className}
        />
      )
    }}
  </InputMask>
)

export const SmsStep = ({
  phone,
  isNew,
  setStep,
  expired,
  nextStep,
  setIsnew,
  setExpired,
  smsError,
  voteRedir,
}) => {
  const hiddenPhone = phone.slice(0, -4) + '****'
  const dispatch = useAuthDispatch()
  const history = useHistory()
  const [trigger, setTrigger] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorSend, setErrorSend] = useState('')
  const smsCode = () => {
    return axios
      .post(`${process.env.REACT_APP_BACKEND}/auth/authenticate`, {
        phone,
      })
      .then((e) => [setExpired(5), setTrigger(!trigger)])
  }

  useEffect(() => {
    smsError && errorSmsToast()
  }, [smsError])

  useEffect(() => {
    scroll('#root')
  }, [])

  return (
    <MainLayout>
      <Bckgr />
      <section className="authorization">
        <div className="authorization__container">
          <h2 className="authorization__title title">
            Всероссийский конкурс гражданских инициатив
          </h2>
          <BreadcrumbsLogin />
          <div className="authorization__step1">
            <p className="authorization__desc">
              Введите полученный в СМС код на Номер {hiddenPhone}.
            </p>
            <Formik
              initialValues={{ code: '' }}
              validate={(values) => {
                const errors = {}
                if (!values.code) {
                  errors.code = 'Обязательное поле'
                }
                if (values.code && values.code.length < 6) {
                  errors.code = 'Не правильный формат кода'
                }

                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setIsLoading(true)
                try {
                  if (!isNew) {
                    return await loginUser(dispatch, {
                      code: values.code,
                      phone: phone,
                    })

                      .then((response) => {
                        
                        if (response.is_new) {
                          setIsnew(true);
                          return nextStep();
                        }
                        response.user
                          ? voteRedir
                            ? history.push('/vote')
                            : history.push('/profile')
                          : setErrorSend(response.message)
                      })
                      .catch((e) => console.log(e))
                  }
                  return await loginUser(dispatch, {
                    code: values.code,
                    phone: phone,
                  })
                    .then((response) => {
                      response.user
                        ? setStep(2)
                        : setErrorSend(response.message)
                    })
                    .catch((e) => {
                      setErrorSend('Телефон пользователя или код не совпадают.')
                    })
                } catch (error) {
                  console.log(error)
                }
                setIsLoading(false)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isValid,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form
                  className="authorization__form authorization__form_3 js-authorization2step-form"
                  onSubmit={handleSubmit}
                  autoComplete={'off'}
                >
                  {errorSend ? (
                    <>
                      <hr />
                      <div className="sendvote__simpletext">{errorSend}</div>
                      <div className="sendvote__input-button to-right">
                        <button
                          className="sendvote__btn-submit button"
                          onClick={() => {
                            setErrorSend('')
                            setIsLoading(false)
                          }}
                        >
                          <span>Повторить</span>
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="authorization__input-group sms">
                      <div className="authorization__input-block">
                        <label htmlFor="phone">6 символов из смс</label>
                        <Input
                          autoComplete={'off'}
                          className={errors.code ? 'input error' : 'input'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code}
                          disabled={isLoading}
                        />
                        <span className="authorization__error">
                          {errors.code && touched.code && errors.code}
                        </span>
                      </div>
                      <div className="authorization__repeat-btn">
                        <TimedBtn
                          initialMinute={expired}
                          trigger={trigger}
                          onClick={smsCode}
                        />
                      </div>
                      <div className="authorization__input-button">
                        <button
                          className="authorization__btn-submit button"
                          type="submit"
                          disabled={!isValid || isLoading}
                        >
                          {isLoading && <Loader />}
                          <span style={{ opacity: isLoading ? 0 : 1 }}>
                            Войти
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}
