export const ShowNomination = ({ nomination, region }) => {
	const selectedRegion =
		region.data.filter(function (item) {
			return item.id === nomination[0].region_id;
		});


	return (
        <section className='profile'>
            <h3
                className='profile__title'
                style={{
                    textAlign: 'center',
                    padding: '30px 0',
                    color: '#00529C',
                }}
            >
                Ваша заявка была принята, большое спасибо за участие !
            </h3>
            <form
                className='profile__form js-registration-form'
                noValidate=''
                style={{margin: '20px auto'}}
            >
                <div className='profile__form-row'>
                    <div className='profile__form-col personal '>
                        <h3 className='profile__title'>
                            Адрес многоквартирного дома
                        </h3>
                        <div className='profile__input-wrapper'>
                            <div className='profile__input-block'>
                                <label>Субъект РФ*</label>
                                <input
                                    className='input'
                                    id='address'
                                    name='address'
                                    type='text'
                                    placeholder='Адрес'
                                    value={selectedRegion[0].name}
                                    disabled
                                />
                            </div>

                            <div className='profile__input-block'>
                                <label htmlFor='address'>Адрес* </label>

                                <input
                                    className='input'
                                    id='address'
                                    name='address'
                                    type='text'
                                    placeholder='Адрес'
                                    value={nomination[0].full_address}
                                    disabled
                                />
                                <span className='profile__error'>
												Поле не должно быть пустым
											</span>
                            </div>
                            <div
                                className='profile__input-block'

                            >
                                <label htmlFor='address'>Год постройки* </label>

                                <input
                                    className='input'
                                    id='address'
                                    name='address'
                                    type='text'
                                    placeholder='Адрес'
                                    value={nomination[0].construction_year}
                                    disabled
                                />
                                <span className='profile__error'>
												Поле не должно быть пустым
											</span>
                            </div>
                            <div
                                className='profile__input-block'

                            >
                                <label htmlFor='address'>Количество этажей* </label>

                                <input
                                    className='input'
                                    id='address'
                                    name='address'
                                    type='text'
                                    placeholder='Адрес'
                                    value={nomination[0].flooring}
                                    disabled
                                />
                                <span className='profile__error'>
												Поле не должно быть пустым
											</span>
                            </div>
                            <div
                                className='profile__input-block'

                            >
                                <label htmlFor='address'>Количество квартир* </label>

                                <input
                                    className='input'
                                    id='address'
                                    name='address'
                                    type='text'
                                    placeholder='Адрес'
                                    value={nomination[0].apartments}
                                    disabled
                                />
                                <span className='profile__error'>
												Поле не должно быть пустым
											</span>
                            </div>
                        </div>
                    </div>

                    <div className='profile__form-col personal '>
                        <h3 className='profile__title'>Номинаци</h3>
                        {nomination[0].nominations?.map((e, i) => {
                            return (
                                <div key={i} className='profile__input-wrapper'>
                                    <div className='profile__input-block'>
                                        <label htmlFor='naming'>Номинация</label>

                                        <input
                                            className='input'
                                            id='naming'
                                            name='naming'
                                            type='text'
                                            placeholder='Наименование'
                                            value={e.nomination}
                                            disabled
                                        />
                                    </div>
                                    <div className='profile__input-block'>
                                        <label htmlFor='naming'>Контактное лицо (ФИО)</label>

                                        <input
                                            className='input'
                                            id='naming'
                                            name='naming'
                                            type='text'
                                            placeholder='Наименование'
                                            value={e.contact_person}
                                            disabled
                                        />
                                    </div>
                                    <div className='profile__input-block'>
                                        <label
                                            htmlFor='naming'>{e.chairman ? 'Председатель Совета (ФИО)' : 'Отношение к дому'}</label>

                                        <input
                                            className='input'
                                            id='naming'
                                            name='naming'
                                            type='text'
                                            placeholder='Наименование'
                                            value={e.chairman ? e.chairman : e.contact_person_position}
                                            disabled
                                        />
                                    </div>
                                    <div className='profile__input-block'>
                                        <label htmlFor='naming'>
                                            Контактный номер телефона
                                        </label>

                                        <input
                                            className='input'
                                            id='naming'
                                            name='naming'
                                            type='text'
                                            placeholder='Наименование'
                                            value={e.contact_phone}
                                            disabled
                                        />
                                    </div>
                                    <div className='profile__input-block'>
                                        <label htmlFor='naming'>Контактный E-mail</label>

                                        <input
                                            className='input'
                                            id='naming'
                                            name='naming'
                                            type='text'
                                            placeholder='Наименование'
                                            value={e.contact_email}
                                            disabled
                                        />
                                    </div>
                                    <div className='profile__input-block'>
                                        <label htmlFor='naming'>Эссе</label>

                                        {e.essay.name}
                                    </div>
                                    <div className='profile__input-block'>
                                        <label htmlFor='smi'>
                                            {' '}
                                            Фотографии (файлы jpg, png)
                                        </label>
                                        <div
                                            className='profile__input-block-wrap'
                                            style={{
                                                flexDirection: 'column',
                                                alignItems: 'unset',
                                            }}
                                        >
                                            {e.images?.map((e) => {
                                                return <span>{e.name}</span>;
                                            })}
                                        </div>
                                    </div>
                                    <div className='profile__input-block'>
                                        <label htmlFor='smi'>
                                            {' '}
                                            Материалы СМИ (ссылки на публикации)
                                        </label>
                                        <div
                                            className='profile__input-block-wrap'
                                            style={{
                                                flexDirection: 'column',
                                                alignItems: 'unset',
                                            }}
                                        >
                                            {e.media?.map((e) => {
                                                return <span>{e}</span>;
                                            })}
                                        </div>
                                    </div>
                                    <div className='profile__input-block'>
                                        <label htmlFor='smi'>
                                            {' '}
                                            Материалы СМИ (ссылки на публикации)
                                        </label>
                                        <div
                                            className='profile__input-block-wrap'
                                            style={{
                                                flexDirection: 'column',
                                                alignItems: 'unset',
                                            }}
                                        >
                                            {e.media_files?.map((e) => {
                                                return <span>{e.name}</span>;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {/* <div className='profile__input-block'>
										<label htmlFor='smi'>Материалы СМИ (до 10 штук)</label>
										<div
											className='profile__input-block-wrap'
											style={{ flexDirection: 'column', alignItems: 'unset' }}
										>
											{nomination[0].medias.map((e) => {
												return <span>{e.name}</span>;
											})}
										</div>
									</div> */}

                        {/* <div
										className='profile__input-block'
										style={{ flexDirection: 'column' }}
									>
										<label htmlFor='photo'>
											Фотографии (не более 10 шт. и не менее 150 Кб каждое)
										</label>
										{nomination[0].images.map((e) => {
											return <span>{e.name}</span>;
										})}
									</div> */}
                    </div>
                </div>
            </form>
        </section>
	);
};
