import { useEffect } from 'react'
import { useAuthState } from 'context'
import { SimpleLayout } from 'components/layout/simpleLayout'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { VotesCardBig } from 'components/votes/voteCardBig'
import { useState } from 'react'
import { Loader } from 'components/loader/loader'
import { updateUser } from 'context/actions'
import { useAuthDispatch, logout } from 'context'
import { TopSimple } from 'blocks'
import { scroll } from 'components/utils'
import Logout from 'assets/images/svg/icon_out.svg'
import FooterBrdr from 'assets/images/svg/footer.svg'
  
export const Profile = () => {
  const { userDetails, token } = useAuthState()
  const [loading, setLoading] = useState(true)
  const dispatch = useAuthDispatch()
  const history = useHistory()

  const handleLogout = () => {
    logout(dispatch)
    history.push('/login')
  }

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios
      .get(`${process.env.REACT_APP_BACKEND}/user/profile`)
      .then((data) => {
        updateUser(dispatch, data.data.data).then(() => setLoading(false))
      })
      .catch(function (err) {
        logout(dispatch)
      })
  }, [token, dispatch, history])

  useEffect(() => {
    scroll('#root')
  }, [])

  if (loading) return <Loader />

  return (
    <SimpleLayout>
      <TopSimple titleText="Личный кабинет" />
      <section className="profile">
      <div className="footer__border">
        <img
          src={FooterBrdr}
          className="footer__border_img"
          alt="Footer Border"
        />
      </div>
        <div className="container profile__container">
          <h2 className="profile__title title">Ваши данные</h2>
          <div className="profile__details">
            <div className="profile__details_field">
              <div className="profile__label">Фамилия</div>
              <div className="profile__value">{userDetails.surname}</div>
            </div>
            <div className="profile__details_field">
              <div className="profile__label">Имя</div>
              <div className="profile__value">{userDetails.name}</div>
            </div>
            <div className="profile__details_field">
              <div className="profile__label">Отчество</div>
              <div className="profile__value">{userDetails.patronymic}</div>
            </div>
            <div className="profile__details_field">
              <div className="profile__label">Дата рождения</div>
              <div className="profile__value">{userDetails.birthday}</div>
            </div>
            <div className="profile__details_field">
              <div className="profile__label">Номер телефона</div>
              <div className="profile__value">
                {'+7 (' +
                  userDetails.phone.substring(0, 3) +
                  ') ' +
                  userDetails.phone.substring(3, 6) +
                  '-' +
                  userDetails.phone.substring(6, 8) +
                  '-' +
                  userDetails.phone.substring(8, 10)}
              </div>
            </div>
            <div className="profile__details_field">
              <div className="profile__label">Адрес электронной почты</div>
              <div className="profile__value">{userDetails.email}</div>
            </div>
            <div className="profile__details_field full">
              <div className="profile__label">Адрес</div>
              <div className="profile__value">{userDetails.address}</div>
            </div>
          </div>
          <div className="profile__bottom">
            <div className="profile__logout" onClick={handleLogout}>
              <img className="main__icon-in" src={Logout} alt="person" />
              <span>Выйти из профиля</span>
            </div>
          </div>
        </div>
        {userDetails.applications?.length > 0 && (
          <div className="profile__vote">
            <div className="container profile__container">
              <h2 className="profile__title title">Ваши заявки:</h2>
              <div className="profile__vote_wrap">
                {userDetails.applications?.map((app) => {
                  return <VotesCardBig key={app.id} votes={app} />
                })}
              </div>
            </div>
          </div>
        )}
        {userDetails.votings?.length > 0 && (
          <div className="profile__voting">
            <div className="container profile__container">
              <h2 className="profile__title title">Вы проголосовали за:</h2>
              <div className="profile__voting_wrap">
                {userDetails.votings?.map((app) => {
                  return <VotesCardBig key={app.id} votes={app} />
                })}
              </div>
            </div>
          </div>
        )}
      </section>
    </SimpleLayout>
  )
}
