import './Steps.scss'

export const Steps = () => {
  return (
    <section id='steps' className="steps">
      <div className="container">
        <h2 className="steps__title title">Этапы реализации конкурса</h2>
        <div className="steps__wrap">
          <div className="steps__item">
            <div className="steps__item_text">
              06 ноября–<br></br>
              04 декабря 2023
            </div>
            <div className="steps__item_desc">Приём заявок</div>
            <div className="steps__item_dot steps__item_dot_start"></div>
          </div>
          <div className="steps__item">
            <div className="steps__item_text">
              05 декабря 2023–<br></br>
              11 января 2024
            </div>
            <div className="steps__item_desc">
              Народное голосование, определение полуфиналистов
              до&nbsp;16&nbsp;января
            </div>
            <div className="steps__item_dot steps__item_dot_middle"></div>
          </div>
          <div className="steps__item">
            <div className="steps__item_text">
              до<br></br>
              30 января 2024
            </div>
            <div className="steps__item_desc">
              Определение Организационным комитетом финалистов
            </div>
            <div className="steps__item_dot steps__item_dot_middle"></div>
          </div>
          <div className="steps__item">
            <div className="steps__item_text">
              до<br></br>
              29 февраля 2024
            </div>
            <div className="steps__item_desc">
              Подведение итогов, объявление победителей
            </div>
            <div className="steps__item_dot steps__item_dot_stop"></div>
          </div>
        </div>
      </div>
    </section>
  )
}
