import { MainLayout } from 'components/layout/mainLayout'
import { MainVotes } from 'components/votes'
import {
  Top,
  SubTop,
  Nominations,
  Profit,
  Steps,
  Comitet,
  Bottom,
} from 'blocks'
import React, { useEffect } from 'react'
import { scroll } from 'components/utils'
// import { Link } from 'react-router-dom'
// import { useAuthState } from 'context'


export const Main = () => {
  useEffect(() => {
    scroll('#root')
  }, [])

  // const { token } = useAuthState()

  return (
    <MainLayout>
      <Top />
      <SubTop />
      <Nominations />
      <Profit />
      <Steps />
      <MainVotes />
      {/* <div className='container'>
        <div className='calendar__banner calendar__banner_danger'>
          <div className='calendar__banner-text'>
            <span>
            Чтобы принять участие в голосовании,<br/> необходимо пройти процедуру верификации
            </span>
          </div>
          <Link to={!token ? '/login' : '/voting'}>
          <button className='main__btn button' type='button'>
          Зарегистрироваться
          </button>
          </Link>
        </div>
      </div> */}
      <Comitet />
      <Bottom />
    </MainLayout>
  )
}
