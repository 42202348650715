import { Link } from 'react-router-dom'
import Figure from 'assets/images/figure.png'
import './Top.scss'

export const Top = () => {

  return (
    <section className="top">
      <div className="top__bg">
        <div className="container top__container">
          <div className="top__left-block">
            <h1 className="top__title">
              Всероссийский конкурс гражданских инициатив
            </h1>
            <p className="top__caption">
              Для НКО и лидеров общественных проектов
            </p>
            <p className="top__text">
              Победители получат Всероссийское признание, поддержку и
              возможность стать кандидатом в&nbsp;депутаты от&nbsp;самой сильной
              партии страны
            </p>
            <div className="top__btn-flex">
              {true && (
                <Link to="/voting">
                  <button className="top__btn button">Посмотреть заявки</button>
                </Link>
              )}
            </div>
            <div className="top__right-block mobile">
              <div className="top__app-figure">
                <img src={Figure} alt="" />
              </div>
            </div>
          </div>
          <div className="top__right-block">
            <div className="top__app-figure">
              <img src={Figure} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
