import React, { useState } from 'react'
import Modal from 'react-modal'
import { useAuthDispatch } from 'context'
import axios from 'axios'
import { updateUser } from 'context/actions'
import { ToastContainer } from 'react-toastify'

Modal.setAppElement('#root')

export const SubmitModal = ({
  isOpen,
  setIsOpen,
  id,
  token,
  setDetailNews,
  setSuccessOpen,
}) => {
  const clearModalState = () => {
    setIsOpen(false)
  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgb(255,255,255,0.5)',
      display: 'flex',
      alignItems: 'center',
      zIndex: 9,
    },
    content: {
      position: 'unset',
      backgroundColor: '#2173BA',
      height: 'fit-content',
      margin: '0 auto',
      top: '0',
      bottom: '0',
      transform: 'unset',
      padding: '25px',
      maxWidth: '520px',
      width: '100%',
    },
  }

  const closeModal = () => clearModalState()
  const dispatch = useAuthDispatch()
  const [errorSend, setErrorSend] = useState('')

  const submit = (id, token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND}/user/vote/${id}`, {
        headers: headers,
      })
      .then((data) => {
        if (data?.data?.data) {
          updateUser(dispatch, data?.data?.data)
        }
        closeModal()
        setSuccessOpen(true)
      })
      .catch((e) => {
        setErrorSend(e?.response?.data?.message ?? 'Что то пошло не так...')
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={'submit_modal'}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={false}
    >
      <div>
        <h2>Голосование</h2>
        <hr />
        {errorSend ? (
          <>
            <div className="sendvote__simpletext">{errorSend}</div>
            <div className="sendvote__input-button to-right">
              <button
                className="sendvote__btn-submit button"
                onClick={() => {
                  setErrorSend('')
                  closeModal()
                }}
              >
                <span>Закрыть</span>
              </button>
            </div>
          </>
        ) : (
          <>
            <p>
              Вы можете проголосовать только за одну заявку в каждой номинации
            </p>
            <div className="submit_modal_btn_wrap">
              <button
                className="main__btn button modal__btn modal__btn-white"
                onClick={() => closeModal()}
              >
                Отмена
              </button>
              <button
                className="main__btn button modal__btn modal__btn-red"
                onClick={() => submit(id, token)}
              >
                Проголосовать
              </button>
            </div>
          </>
        )}
      </div>
      <ToastContainer />
    </Modal>
  )
}
