import VideoPrev from 'assets/images/content/video-preview.jpg'

import './SubTop.scss'

export const SubTop = () => {
  return (
    <section id="subtop" className="subtop">
      <div className="container subtop__container">
        <div className="subtop__container_wrap">
          <div className="subtop__text">
            <div className="subtop__text_title">О конкурсе</div>
            <div className="subtop__text_descr">
              <p>
                Всероссийский конкурс гражданских инициатив&nbsp;&mdash; это
                конкурс для представителей некоммерческого сектора
                и&nbsp;лидеров общественных проектов.
              </p>

              <p>
                Конкурс даст возможность получить поддержку
                до&nbsp;3&nbsp;000&nbsp;000 рублей на&nbsp;развитие НКО
                и&nbsp;проектов, помощь в&nbsp;реализации и&nbsp;масштабировании
                своих инициатив, а&nbsp;также пройти бесплатное обучение
                и&nbsp;возможность стать кандидатом в&nbsp;депутаты.
              </p>

              <p>
                Для участия необходимо подать заявку на&nbsp;сайте
                до&nbsp;4&nbsp;декабря 2023 года (включительно).
              </p>

              <p>Приглашаем всех лидеров присоединиться к&nbsp;конкурсу!</p>
            </div>
          </div>
          <div className="subtop__video">
            <video
              controls
              disablePictureInPicture
              controlsList="nodownload noremoteplayback noplaybackrate"
              poster={VideoPrev}
            >
              <source
                src="https://sila-idey-api.er.ru/video/sila-idei.mp4"
                type="video/mp4"
                preload="none"
              />
            </video>
          </div>
        </div>
      </div>
    </section>
  )
}
