import Ico1 from 'assets/images/content/profit/ico1.svg'
import Ico2 from 'assets/images/content/profit/ico2.svg'
import Ico3 from 'assets/images/content/profit/ico3.svg'
import Ico4 from 'assets/images/content/profit/ico4.svg'
import Ico5 from 'assets/images/content/profit/ico5.svg'
import Ico6 from 'assets/images/content/profit/ico6.svg'
import Ico7 from 'assets/images/content/profit/ico7.svg'
import Ico8 from 'assets/images/content/profit/ico8.svg'
import './Profit.scss'

export const Profit = () => {
  return (
    <section className="profit">
      <div className="container">
        <h2 className="profit__title title">Что получают победители?</h2>
        <div className="profit__wrap">
          <div className="profit__item">
            <img className="profit__item_ico" src={Ico1} alt="" />
            <div className="profit__item_text">
              Поддержка до&nbsp;3&nbsp;000&nbsp;000 рублей
            </div>
          </div>
          <div className="profit__item">
            <img className="profit__item_ico" src={Ico2} alt="" />
            <div className="profit__item_text">
              Помощь партии &laquo;Единая россия&raquo; в&nbsp;реализации идеи,
              масштабировании проекта или работы нко
            </div>
          </div>
          <div className="profit__item">
            <img className="profit__item_ico" src={Ico3} alt="" />
            <div className="profit__item_text">
              Возможность рассказать о&nbsp;себе и&nbsp;своём проекте
              руководству партии
            </div>
          </div>
          <div className="profit__item">
            <img className="profit__item_ico" src={Ico4} alt="" />
            <div className="profit__item_text">
              Обучение в&nbsp;высшей партийной школе для прохождения
              предварительного голосования с&nbsp;возможностью стать кандидатом
              в&nbsp;депутаты разного уровня
            </div>
          </div>
          <div className="profit__item">
            <img className="profit__item_ico" src={Ico5} alt="" />
            <div className="profit__item_text">Продвижение в&nbsp;СМИ</div>
          </div>
          <div className="profit__item">
            <img className="profit__item_ico" src={Ico6} alt="" />
            <div className="profit__item_text">
              Индивидуальное
              <br />
              PR-консультирование
            </div>
          </div>
          <div className="profit__item">
            <img className="profit__item_ico" src={Ico7} alt="" />
            <div className="profit__item_text">Продюсирование проекта</div>
          </div>
          <div className="profit__item">
            <img className="profit__item_ico" src={Ico8} alt="" />
            <div className="profit__item_text">
              Консультации по&nbsp;формированию и&nbsp;продвижению личного
              бренда
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
