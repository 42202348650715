import { useEffect } from 'react'
import { useAuthState } from 'context'
import { MainLayout } from 'components/layout/mainLayout'
import { Bckgr } from 'blocks'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { Loader } from 'components/loader/loader'
import { updateUser } from 'context/actions'
import { useAuthDispatch, logout } from 'context'
import { scroll } from 'components/utils'
import BreadcrumbsProfile from 'components/breadcrumbsProfile'
import Dot from 'assets/images/content/vote/dot.svg'
import DotSc from 'assets/images/content/vote/dot-sc.svg'

export const Vote = () => {
  const { token } = useAuthState()
  const [loading, setLoading] = useState(true)
  const [votedItems, setVotedItems] = useState({ 1: false, 2: false, 3: false })
  const [canVoted, setCanVoted] = useState(true)
  const dispatch = useAuthDispatch()
  const history = useHistory()

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      axios
        .get(`${process.env.REACT_APP_BACKEND}/user/profile`)
        .then((data) => {
          updateUser(dispatch, data.data.data).then(() => {
            let app = data.data.data.applications
            let res = votedItems
            if (app.length > 0) {
              for (var i = 0; i < app.length; i++) {
                res[app[i].nomination_id] = true
              }
              setCanVoted(false)
            } else {
              setCanVoted(true)
            }

            setVotedItems(res)
            setLoading(false)
          })
        })
        .catch(function (err) {
          logout(dispatch)
        })
    } else {
      setLoading(false)
    }
  }, [token, dispatch, history, votedItems])
  useEffect(() => {
    scroll('#root')
  }, [])

  return (
    <MainLayout>
      <Bckgr />
      <section className="vote">
        <div className="container vote__container">
          {loading ? (
            <Loader />
          ) : (
            <>
              <BreadcrumbsProfile />
              <h2 className="vote__title title">Подача заявки</h2>
              <div className="vote__descr">Выберите номинацию</div>
              <div className="vote__cards">
                <div className={'vote__card ' + (votedItems[1] ? 'voted' : '')}>
                  <img
                    className="vote__card_dot"
                    src={votedItems[1] ? DotSc : Dot}
                    alt=""
                  ></img>
                  <div className="vote__card_title">
                    Лучшая
                    <br />
                    НКО
                  </div>
                  <div className="vote__card_descr">
                    Определяется лучшая НКО (всероссийская, региональная,
                    местная) по работе в 2023 году. На момент подачи заявки НКО
                    должно быть не менее 2 лет с момента регистрации.
                  </div>
                  {votedItems[1] ? (
                    <div className="vote__card_voted">Заявка отправлена</div>
                  ) : canVoted ? (

                      <button disabled className="vote__card_btn button">выбрать</button>
                  ) : (
                    <div className="vote__card_voted"></div>
                  )}
                </div>
                <div className={'vote__card ' + (votedItems[2] ? 'voted' : '')}>
                  <img
                    className="vote__card_dot"
                    src={votedItems[2] ? DotSc : Dot}
                    alt=""
                  ></img>
                  <div className="vote__card_title">
                    Лучший
                    <br />
                    проект
                  </div>
                  <div className="vote__card_descr">
                    Определяются лучшие проекты, реализованные в 2023 году как
                    юридическими, так и физическими лицами.
                  </div>
                  {votedItems[2] ? (
                    <div className="vote__card_voted">Заявка отправлена</div>
                  ) : canVoted ? (
                      <button disabled className="vote__card_btn button">выбрать</button>
                  ) : (
                    <div className="vote__card_voted"></div>
                  )}
                </div>
                <div className={'vote__card ' + (votedItems[3] ? 'voted' : '')}>
                  <img
                    className="vote__card_dot"
                    src={votedItems[3] ? DotSc : Dot}
                    alt=""
                  ></img>
                  <div className="vote__card_title">
                    Лучшая
                    <br />
                    идея
                  </div>
                  <div className="vote__card_descr">
                    Определяются лучшие идеи как юридических, так и физических
                    лиц, которые могут быть реализованы в 2024 году и могут
                    повлиять на социально-экономическое развитие населенного
                    пункта, региона и страны.
                  </div>
                  {votedItems[3] ? (
                    <div className="vote__card_voted">Заявка отправлена</div>
                  ) : canVoted ? (
                      <button disabled className="vote__card_btn button">выбрать</button>

                  ) : (
                    <div className="vote__card_voted"></div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="vote__ext">
            Каждый пользователь может подать только 1 заявку на Конкурс, а в
            последствии голосовать один раз в каждой номинации.
          </div>
          <div className='vote__ext' style={{color: 'red'}}>
          Приём заявок закончен 04 декабря 2023
          </div>
        </div>
      </section>
    </MainLayout>
  )
}
