import React, { useEffect, useState } from 'react'
import { FormSelect } from 'components/inputs/selectField'
import { Formik, Form, Field } from 'formik'
import axios from 'axios'
import { useAuthState } from 'context'
import { DateInput } from 'components/inputs/birthdayInput'
import { scroll } from 'components/utils'
import { ErrorModal } from 'components/modals/ErrorModal'
import {
  validateNomination1,
  validateNomination2,
  validateNomination3,
} from '../validation'
import { useHistory } from 'react-router-dom'
import { formatDate2, rebuildData } from 'components/utils'
import { ToastContainer } from 'react-toastify';
import { errorRegisterToast } from '../../notifications';
import { Loader } from '../../loader/loader'
import Pixel from 'assets/images/px.png'
import Loadimage from 'assets/images/content/sendvote/loadimage.svg'
import Attach from 'assets/images/content/sendvote/attach.svg'
import Cross from 'assets/images/content/sendvote/cross.svg'
import AcceptFile from 'assets/pdf/podtverzhdenie-podachi-zayavki.docx'
import Order from 'assets/pdf/order.docx'


export const Nomination = (params) => {
  const { userDetails, token } = useAuthState()
  const [selectedRegionOption, setSelectedRegionOption] = useState(
    userDetails?.district?.region?.id || null,
  )
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const [date, setDate] = useState('')
  const [attachFiles, setAttachFiles] = useState([])
  const [ustavFiles, setUstavFiles] = useState([])
  const [linksProject, setLinksProject] = useState([])
  const [errorSend, setErrorSend] = useState({})
  const [successSend, setSuccessSend] = useState(false)

  const nominationFilterOptions = [
    { value: 1, label: 'Лучшая НКО' },
    { value: 2, label: 'Лучший проект' },
    { value: 3, label: 'Лучшая идея' },
  ]
  const levelFilterOptions = [
    { value: 1, label: 'Всероссийский' },
    { value: 2, label: 'Региональный' },
    { value: 3, label: 'Местный' },
  ]

  useEffect(() => {
    if (!userDetails.name) {
      history.push('/login')
    }
    if (!params.nomination) {
      history.push('/vote')
    }
    if (userDetails?.applications?.length > 0) {
      userDetails.applications.map((item) => {
        if (item.nomination_id === params.nomination) {
          history.push('/vote')
        }
        return null
      })
    }
  }, [params, history, userDetails])

  useEffect(() => {
    scroll('#root')
  }, [])

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }
  function addAttachFiles(filesObj) {
    let res = []
    if (attachFiles.length > 0) {
      res = attachFiles
    }
    if (filesObj.length > 0) {
      Object.keys(filesObj).forEach(function (key, index) {
        if (
          filesObj[key].size /
          1000 >
          5000
        ) {
          return errorRegisterToast(
            'Прикрепите файл размером меньше 5мб'
          );
        }
        res.push(filesObj[key])
      })
    }
    setAttachFiles([...res])
  }
  function deleteAttachFile(name) {
    let res = []
    if (attachFiles.length > 0) {
      attachFiles.forEach((file) => {
        if (file.name !== name) {
          res.push(file)
        }
      })
    }
    setAttachFiles([...res])
  }
  function addUstavFiles(filesObj, callback) {
    let res = []
    if (ustavFiles.length > 0) {
      res = ustavFiles
    }
    if (filesObj.length > 0) {
      Object.keys(filesObj).forEach(function (key, index) {
        if (
          filesObj[key].size /
          1000 >
          5000
        ) {
          return errorRegisterToast(
            'Прикрепите файл размером меньше 5мб'
          );
        }
        res.push(filesObj[key])
      })
    }
    setUstavFiles([...res])
    callback(res.length)
  }
  function deleteUstavFile(name) {
    let res = []
    if (ustavFiles.length > 0) {
      ustavFiles.forEach((file) => {
        if (file.name !== name) {
          res.push(file)
        }
      })
    }
    setUstavFiles([...res])
  }
  function addLinksMulti(link, calback) {
    let res = []
    let double = false
    if (linksProject.length > 0 && link) {
      linksProject.forEach((lnk) => {
        if (lnk === link) {
          double = true
        }
      })
    }
    if (double) {
      res = linksProject
      setLinksProject([...res])
      calback(res.length)
    } else {
      res = linksProject
      if (link) {
        res.push(link)
      }
      setLinksProject([...res])
      calback(res.length)
    }
  }
  function deleteLinksMulti(link) {
    let res = []
    if (linksProject.length > 0) {
      linksProject.forEach((lnk) => {
        if (lnk !== link) {
          res.push(lnk)
        }
      })
    }
    setLinksProject([...res])
  }

  return (
    <>
      <Formik
        initialValues={{
          nomination_id: params?.nomination,
          is_individual: true,
          organisation_level_id: 1,
          image_application: '',
          name: '',

          company_name: '',
          company_fio: '',
          individual_position: '',
          region_id: '',

          individual_name: '',
          individual_phone: '',
          individual_email: '',

          description: '',
          actual: '',

          company_links_Site: '',
          company_links_SnOK: '',
          company_links_SnVK: '',
          company_links_Telegram: '',

          individual_links_Site: '',
          individual_links_SnOK: '',
          individual_links_VK: '',
          individual_links_Telegram: '',

          project_links_SMI: '',
          project_links_SnOK: '',
          project_links_SnVK: '',
          project_links_Telegram: '',

          achievements_1: '',
          achievements_2: '',
          achievements_3: '',
          achievements_4: '',
          achievements_5: '',

          company_nko_count_peaple: '',
          company_nko_peaple_achievements: '',
          company_nko_count_employee: '',
          company_nko_employee_achievements: '',

          company_nko_resource_profit: '',

          file_smeta: '',
          file_smeta_prev: '',
          company_address: '',
          company_phone: '',
          company_email: '',
          company_file_ustavnie_multi: '',
          company_file_ustavnie_hidden: ustavFiles.length,
          company_created_date: '',
          selected_date: '',
          company_file_zero_debt: '',
          company_file_zero_debt_prev: '',
          company_file_appruve: '',
          company_file_appruve_prev: '',
          files_multi: '',
          links_multi: '',
          links_hidden: linksProject.length,
          personal_data_confirmation: false,
          agree: false,
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validate={
          params.nomination === 1
            ? validateNomination1
            : params.nomination === 2
              ? validateNomination2
              : params.nomination === 3
                ? validateNomination3
                : null
        }
        onSubmit={({ ...values }) => {
          setIsLoading(true)
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          }
          values.company_phone = values.company_phone
            ? values.company_phone.replaceAll(/[+()\-\s]/g, '').substring(1)
            : ''
          values.individual_phone = values.individual_phone
            ? values.individual_phone.replaceAll(/[+()\-\s]/g, '').substring(1)
            : ''
          const data = rebuildData({
            ...values,
            ustavFiles: ustavFiles,
            attachFiles: attachFiles,
            linksProject: linksProject,
          })
          axios
            .post(`${process.env.REACT_APP_BACKEND}/user/application`, data, {
              headers: headers,
            })
            .then((data) => {
              setSuccessSend(true)
            })
            .catch((e) =>
              setErrorSend(
                e?.response?.data?.errors
                  ? e.response.data.errors
                  : { error: ['Что то пошло не так...'] },
              ),
            )
            .finally(() => setIsLoading(false))
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          return (
            <>
              <h2 className="sendvote__title title">
                {successSend
                  ? 'Ваш заявка принята'
                  : nominationFilterOptions.filter((item) => {
                    return (
                      params?.nomination && item.value === params?.nomination
                    )
                  })[0]?.label || 'Подача заявки'}
              </h2>

              {successSend ? (
                <>
                  <hr />
                  <div className="sendvote__simpletext">
                    По завершению модерации заявка будет отображена на сайте
                  </div>
                  <div className="sendvote__input-button to-right">
                    <button
                      className="sendvote__btn-submit button"
                      onClick={() => {
                        history.push('/vote')
                      }}
                    >
                      <span>Закрыть</span>
                    </button>
                  </div>
                </>
              ) : (
                <Form
                  onKeyDown={onKeyDown}
                  className="sendvote__form js-registration-form"
                >
                  {params.nomination !== 1 && (
                    <>
                      {/* Инициатор проекта */}
                      <div className="sendvote__descr">Инициатор проекта</div>
                      <div className="sendvote__input-group double checkboxes">
                        <div className="sendvote__checkbox-block">
                          <label>
                            <Field
                              name="is_individual"
                              type="checkbox"
                              checked={values.is_individual === true}
                              onChange={() => {
                                setFieldValue('is_individual', true)
                                setFieldValue('individual_position', '')
                                setFieldValue('company_links_Site', '')
                                setFieldValue('company_links_SnOK', '')
                                setFieldValue('company_links_SnVK', '')
                                setFieldValue('company_links_Telegram', '')
                                setFieldValue('company_name', '')
                                setFieldValue('company_address', '')
                                setFieldValue('company_created_date', '')
                                setFieldValue('company_file_zero_debt', '')
                                setFieldValue('company_file_appruve', '')
                                setUstavFiles([])
                              }}
                            />
                            <span>Физическое лицо</span>
                          </label>
                        </div>
                        <div className="sendvote__checkbox-block">
                          <label>
                            <Field
                              name="is_individual"
                              type="checkbox"
                              checked={values.is_individual === false}
                              onChange={() => {
                                setFieldValue('is_individual', false)
                              }}
                            />
                            <span>Юридическое лицо</span>
                          </label>
                        </div>
                        <span className="sendvote__error">
                          {errors.is_individual &&
                            touched.is_individual &&
                            errors.is_individual}
                        </span>
                      </div>

                      <hr />
                    </>
                  )}

                  {/* география реализации */}
                  <div className="sendvote__descr">
                    Выберите географию реализации
                  </div>
                  <div className="sendvote__input-group triple checkboxes">
                    {levelFilterOptions.map((tag) => (
                      <div className="sendvote__checkbox-block" key={tag.value}>
                        <label>
                          <Field
                            name="organisation_level_id"
                            type="checkbox"
                            checked={values.organisation_level_id === tag.value}
                            onChange={() => {
                              setFieldValue('organisation_level_id', tag.value)
                            }}
                          />
                          <span>{tag.label}</span>
                        </label>
                      </div>
                    ))}
                    <span className="sendvote__error">
                      {errors.organisation_level_id &&
                        touched.organisation_level_id &&
                        errors.organisation_level_id}
                    </span>
                  </div>

                  <hr />

                  {/* Контактные данные заявителя */}
                  <div className="sendvote__descr">
                    Контактные данные заявителя
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="company_fio">ФИО заявителя*</label>
                      <Field
                        className={
                          errors.company_fio && touched.company_fio
                            ? 'input error'
                            : 'input'
                        }
                        id="company_fio"
                        name="company_fio"
                        type="text"
                        placeholder="ФИО заявителя"
                        onChange={handleChange}
                        value={values.company_fio}
                      />
                      <span className="sendvote__error">
                        {errors.company_fio &&
                          touched.company_fio &&
                          errors.company_fio}
                      </span>
                    </div>
                  </div>
                  {(params.nomination === 1 ||
                    values.is_individual === false) && (
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block w-full">
                          <label htmlFor="individual_position">
                            Должность в НКО*
                          </label>
                          <Field
                            className={
                              errors.individual_position &&
                                touched.individual_position
                                ? 'input error'
                                : 'input'
                            }
                            id="individual_position"
                            name="individual_position"
                            type="text"
                            placeholder="Должность в НКО"
                            onChange={handleChange}
                            value={values.individual_position}
                          />
                          <span className="sendvote__error">
                            {errors.individual_position &&
                              touched.individual_position &&
                              errors.individual_position}
                          </span>
                        </div>
                      </div>
                    )}
                  {/* Ссылки */}
                  {(params.nomination === 1 ||
                    values.is_individual === false) && (
                      <>
                        <div className="sendvote__subdescr">
                          Ссылки на сайт и социальные сети НКО
                        </div>
                        <div className="sendvote__input-group double">
                          <div className="sendvote__input-block">
                            <label htmlFor="company_links_Site">
                              Ссылка на cайт НКО**
                            </label>
                            <Field
                              className={
                                errors.company_links_Site &&
                                  touched.company_links_Site
                                  ? 'input error'
                                  : 'input'
                              }
                              id="company_links_Site"
                              name="company_links_Site"
                              type="text"
                              placeholder="Ссылка на cайт НКО"
                              onChange={handleChange}
                              value={values.company_links_Site}
                            />
                            <span className="sendvote__error">
                              {errors.company_links_Site &&
                                touched.company_links_Site &&
                                errors.company_links_Site}
                            </span>
                          </div>
                          <div className="sendvote__input-block">
                            <label htmlFor="company_links_SnOK">
                              Ссылка на Одноклассники**
                            </label>
                            <Field
                              className={
                                errors.company_links_SnOK &&
                                  touched.company_links_SnOK
                                  ? 'input error'
                                  : 'input'
                              }
                              id="company_links_SnOK"
                              name="company_links_SnOK"
                              type="text"
                              placeholder="Ссылка на Одноклассники"
                              onChange={handleChange}
                              value={values.company_links_SnOK}
                            />
                            <span className="sendvote__error">
                              {errors.company_links_SnOK &&
                                touched.company_links_SnOK &&
                                errors.company_links_SnOK}
                            </span>
                          </div>
                        </div>
                        <div className="sendvote__input-group double">
                          <div className="sendvote__input-block">
                            <label htmlFor="company_links_SnVK">
                              Ссылка на VK**
                            </label>
                            <Field
                              className={
                                errors.company_links_SnVK &&
                                  touched.company_links_SnVK
                                  ? 'input error'
                                  : 'input'
                              }
                              id="company_links_SnVK"
                              name="company_links_SnVK"
                              type="text"
                              placeholder="Ссылка на VK"
                              onChange={handleChange}
                              value={values.company_links_SnVK}
                            />
                            <span className="sendvote__error">
                              {errors.company_links_SnVK &&
                                touched.company_links_SnVK &&
                                errors.company_links_SnVK}
                            </span>
                          </div>
                          <div className="sendvote__input-block">
                            <label htmlFor="company_links_Telegram">
                              Ссылка на Телеграм**
                            </label>
                            <Field
                              className={
                                errors.company_links_Telegram &&
                                  touched.company_links_Telegram
                                  ? 'input error'
                                  : 'input'
                              }
                              id="company_links_Telegram"
                              name="company_links_Telegram"
                              type="text"
                              placeholder="Ссылка на Телеграм"
                              onChange={handleChange}
                              value={values.company_links_Telegram}
                            />
                            <span className="sendvote__error">
                              {errors.company_links_Telegram &&
                                touched.company_links_Telegram &&
                                errors.company_links_Telegram}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  <div className="sendvote__subdescr">
                    Ссылки на сайт и социальные сети заявителя
                  </div>
                  <div className="sendvote__input-group double">
                    <div className="sendvote__input-block">
                      <label htmlFor="individual_links_Site">
                        Ссылка на cайт**
                      </label>
                      <Field
                        className={
                          errors.individual_links_Site &&
                            touched.individual_links_Site
                            ? 'input error'
                            : 'input'
                        }
                        id="individual_links_Site"
                        name="individual_links_Site"
                        type="text"
                        placeholder="Ссылка на cайт"
                        onChange={handleChange}
                        value={values.individual_links_Site}
                      />
                      <span className="sendvote__error">
                        {errors.individual_links_Site &&
                          touched.individual_links_Site &&
                          errors.individual_links_Site}
                      </span>
                    </div>
                    <div className="sendvote__input-block">
                      <label htmlFor="individual_links_SnOK">
                        Ссылка на Одноклассники**
                      </label>
                      <Field
                        className={
                          errors.individual_links_SnOK &&
                            touched.individual_links_SnOK
                            ? 'input error'
                            : 'input'
                        }
                        id="individual_links_SnOK"
                        name="individual_links_SnOK"
                        type="text"
                        placeholder="Ссылка на Одноклассники"
                        onChange={handleChange}
                        value={values.individual_links_SnOK}
                      />
                      <span className="sendvote__error">
                        {errors.individual_links_SnOK &&
                          touched.individual_links_SnOK &&
                          errors.individual_links_SnOK}
                      </span>
                    </div>
                  </div>
                  <div className="sendvote__input-group double">
                    <div className="sendvote__input-block">
                      <label htmlFor="individual_links_VK">
                        Ссылка на VK**
                      </label>
                      <Field
                        className={
                          errors.individual_links_VK &&
                            touched.individual_links_VK
                            ? 'input error'
                            : 'input'
                        }
                        id="individual_links_VK"
                        name="individual_links_VK"
                        type="text"
                        placeholder="Ссылка на VK"
                        onChange={handleChange}
                        value={values.individual_links_VK}
                      />
                      <span className="sendvote__error">
                        {errors.individual_links_VK &&
                          touched.individual_links_VK &&
                          errors.individual_links_VK}
                      </span>
                    </div>
                    <div className="sendvote__input-block">
                      <label htmlFor="individual_links_Telegram">
                        Ссылка на Телеграм**
                      </label>
                      <Field
                        className={
                          errors.individual_links_Telegram &&
                            touched.individual_links_Telegram
                            ? 'input error'
                            : 'input'
                        }
                        id="individual_links_Telegram"
                        name="individual_links_Telegram"
                        type="text"
                        placeholder="Ссылка на Телеграм"
                        onChange={handleChange}
                        value={values.individual_links_Telegram}
                      />
                      <span className="sendvote__error">
                        {errors.individual_links_Telegram &&
                          touched.individual_links_Telegram &&
                          errors.individual_links_Telegram}
                      </span>
                    </div>
                  </div>

                  <hr />

                  {params.nomination !== 1 &&
                    values.is_individual === false && (
                      <>
                        {/* Информация об организации */}
                        <div className="sendvote__descr">
                          Информация об организации
                        </div>
                        <div className="sendvote__input-group">
                          <div className="sendvote__input-block w-full">
                            <label htmlFor="company_name">
                              Полное название НКО*
                            </label>
                            <Field
                              className={
                                errors.company_name && touched.company_name
                                  ? 'input error'
                                  : 'input'
                              }
                              id="company_name"
                              name="company_name"
                              type="text"
                              placeholder="Полное название НКО"
                              onChange={handleChange}
                              value={values.company_name}
                            />
                            <span className="sendvote__error">
                              {errors.company_name &&
                                touched.company_name &&
                                errors.company_name}
                            </span>
                          </div>
                        </div>
                        <div className="sendvote__input-group">
                          <div className="sendvote__input-block w-full">
                            <label htmlFor="company_address">
                              Юридический адрес НКО (регион, населенный пункт,
                              улица, дом, квартира)*
                            </label>
                            <Field
                              className={
                                errors.company_address &&
                                  touched.company_address
                                  ? 'input error'
                                  : 'input'
                              }
                              id="company_address"
                              name="company_address"
                              type="text"
                              placeholder="Юридический адрес НКО"
                              onChange={handleChange}
                              value={values.company_address}
                            />
                            <span className="sendvote__error">
                              {errors.company_address &&
                                touched.company_address &&
                                errors.company_address}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                  {/* Информация об проекте */}
                  <div className="sendvote__descr">
                    {params.nomination === 1
                      ? 'Информация об организации'
                      : params.nomination === 2
                        ? 'Информация о проекте'
                        : params.nomination === 3
                          ? 'Информация об идее'
                          : ''}
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="image_application">
                        Фотография / обложка карточки (будет отображаться при
                        голосовании)*
                      </label>
                      <div className="sendvote__imageprev">
                        <img src={imagePreview || Pixel} alt=""></img>
                        {!imagePreview && (
                          <div className="sendvote__loadimg">
                            <img src={Loadimage} alt=""></img>
                          </div>
                        )}
                        <input
                          className={
                            errors.image_application &&
                              touched.image_application
                              ? 'image error'
                              : 'image'
                          }
                          id="image_application"
                          name="image_application"
                          // accept="image/png,image/jpeg,image/jpg"
                          type="file"
                          onChange={(e) => {
                            if (
                              e.target.files[0].size /
                              1000 >
                              5000
                            ) {
                              return errorRegisterToast(
                                'Прикрепите файл размером меньше 5мб'
                              );
                            }
                            setFieldValue(
                              'image_application',
                              e.target.files[0],
                            )
                            const fileReader = new FileReader()
                            fileReader.onload = () => {
                              if (fileReader.readyState === 2) {
                                setImagePreview(fileReader.result)
                              }
                            }
                            fileReader.readAsDataURL(e.target.files[0])
                          }}
                        />
                      </div>
                      <span className="sendvote__error">
                        {errors.image_application &&
                          touched.image_application &&
                          errors.image_application}
                      </span>
                    </div>
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="region_id">Регион*</label>
                      <Field
                        name="region_id"
                        id="region_id"
                        className={
                          errors.region_id && touched.region_id
                            ? 'select error'
                            : 'select'
                        }
                        component={FormSelect}
                        placeholder="Регион"
                        value={selectedRegionOption}
                        onChange={(e) => {
                          setSelectedRegionOption(e)
                        }}
                        options={params?.region?.data?.map((e) => {
                          return { value: e.id, label: e.name }
                        })}
                      />
                      <span className="sendvote__error">
                        {errors.region_id &&
                          touched.region_id &&
                          errors.region_id}
                      </span>
                    </div>
                  </div>
                  {params.nomination !== 1 && (
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block w-full">
                        <label htmlFor="name">
                          {params.nomination === 2
                            ? 'Наименование проекта*'
                            : params.nomination === 3
                              ? 'Наименование идеи*'
                              : ''}
                        </label>
                        <Field
                          className={
                            errors.name && touched.name
                              ? 'input error'
                              : 'input'
                          }
                          id="name"
                          name="name"
                          type="text"
                          placeholder={
                            params.nomination === 2
                              ? 'Наименование проекта'
                              : params.nomination === 3
                                ? 'Наименование идеи'
                                : ''
                          }
                          onChange={handleChange}
                          value={values.name}
                        />
                        <span className="sendvote__error">
                          {errors.name && touched.name && errors.name}
                        </span>
                      </div>
                    </div>
                  )}
                  {params.nomination === 1 && (
                    <>
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block w-full">
                          <label htmlFor="company_name">
                            Полное название НКО*
                          </label>
                          <Field
                            className={
                              errors.company_name && touched.company_name
                                ? 'input error'
                                : 'input'
                            }
                            id="company_name"
                            name="company_name"
                            type="text"
                            placeholder="Полное название НКО"
                            onChange={handleChange}
                            value={values.company_name}
                          />
                          <span className="sendvote__error">
                            {errors.company_name &&
                              touched.company_name &&
                              errors.company_name}
                          </span>
                        </div>
                      </div>
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block w-full">
                          <label htmlFor="company_address">
                            Юридический адрес НКО (регион, населенный пункт,
                            улица, дом, квартира)*
                          </label>
                          <Field
                            className={
                              errors.company_address && touched.company_address
                                ? 'input error'
                                : 'input'
                            }
                            id="company_address"
                            name="company_address"
                            type="text"
                            placeholder="Юридический адрес НКО"
                            onChange={handleChange}
                            value={values.company_address}
                          />
                          <span className="sendvote__error">
                            {errors.company_address &&
                              touched.company_address &&
                              errors.company_address}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {params.nomination !== 1 && (
                    <>
                      {/* Цели и задачи */}
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block w-full">
                          <label htmlFor="description">
                            {params.nomination === 2
                              ? 'Краткое описание проекта (отображается при голосовании)*'
                              : params.nomination === 3
                                ? 'Краткое описание идеи (отображается при голосовании)*'
                                : ''}
                          </label>
                          <Field
                            id="description"
                            name="description"
                            type="textarea"
                            component="textarea"
                            placeholder={
                              params.nomination === 2
                                ? 'Краткое описание проекта'
                                : params.nomination === 3
                                  ? 'Краткое описание идеи'
                                  : ''
                            }
                            onChange={handleChange}
                            value={values.description}
                          />
                          <span className="sendvote__error">
                            {errors.description &&
                              touched.description &&
                              errors.description}
                          </span>
                        </div>
                      </div>
                      {/* Актуальность */}
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block w-full">
                          <label htmlFor="actual">Актуальность*</label>
                          <Field
                            className={
                              errors.achievements_2 && touched.achievements_2
                                ? 'input error'
                                : 'input'
                            }
                            id="actual"
                            name="actual"
                            type="text"
                            placeholder="Актуальность"
                            onChange={handleChange}
                            value={values.actual}
                          />
                          <span className="sendvote__error">
                            {errors.actual && touched.actual && errors.actual}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {params.nomination === 2 && (
                    <>
                      <div className="sendvote__subdescr">Ссылки на проект</div>
                      <div className="sendvote__input-group double">
                        <div className="sendvote__input-block">
                          <label htmlFor="project_links_SMI">
                            Ссылка на СМИ**
                          </label>
                          <Field
                            className={
                              errors.project_links_SMI &&
                                touched.project_links_SMI
                                ? 'input error'
                                : 'input'
                            }
                            id="project_links_SMI"
                            name="project_links_SMI"
                            type="text"
                            placeholder="Ссылка на СМИ"
                            onChange={handleChange}
                            value={values.project_links_SMI}
                          />
                          <span className="sendvote__error">
                            {errors.project_links_SMI &&
                              touched.project_links_SMI &&
                              errors.project_links_SMI}
                          </span>
                        </div>
                        <div className="sendvote__input-block">
                          <label htmlFor="project_links_SnOK">
                            Ссылка на Одноклассники**
                          </label>
                          <Field
                            className={
                              errors.project_links_SnOK &&
                                touched.project_links_SnOK
                                ? 'input error'
                                : 'input'
                            }
                            id="project_links_SnOK"
                            name="project_links_SnOK"
                            type="text"
                            placeholder="Ссылка на Одноклассники"
                            onChange={handleChange}
                            value={values.project_links_SnOK}
                          />
                          <span className="sendvote__error">
                            {errors.project_links_SnOK &&
                              touched.project_links_SnOK &&
                              errors.project_links_SnOK}
                          </span>
                        </div>
                      </div>
                      <div className="sendvote__input-group double">
                        <div className="sendvote__input-block">
                          <label htmlFor="project_links_SnVK">
                            Ссылка на VK**
                          </label>
                          <Field
                            className={
                              errors.project_links_SnVK &&
                                touched.project_links_SnVK
                                ? 'input error'
                                : 'input'
                            }
                            id="project_links_SnVK"
                            name="project_links_SnVK"
                            type="text"
                            placeholder="Ссылка на VK"
                            onChange={handleChange}
                            value={values.project_links_SnVK}
                          />
                          <span className="sendvote__error">
                            {errors.project_links_SnVK &&
                              touched.project_links_SnVK &&
                              errors.project_links_SnVK}
                          </span>
                        </div>
                        <div className="sendvote__input-block">
                          <label htmlFor="project_links_Telegram">
                            Ссылка на Телеграм**
                          </label>
                          <Field
                            className={
                              errors.project_links_Telegram &&
                                touched.project_links_Telegram
                                ? 'input error'
                                : 'input'
                            }
                            id="project_links_Telegram"
                            name="project_links_Telegram"
                            type="text"
                            placeholder="Ссылка на Телеграм"
                            onChange={handleChange}
                            value={values.project_links_Telegram}
                          />
                          <span className="sendvote__error">
                            {errors.project_links_Telegram &&
                              touched.project_links_Telegram &&
                              errors.project_links_Telegram}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {params.nomination === 3 && (
                    <>
                      {/* Список ссылок */}
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block w-full links_multi">
                          <label htmlFor="links_multi">
                            Ссылка на подобный реализованный проект{' '}
                          </label>
                          <Field
                            className={
                              errors.links_hidden && touched.links_hidden
                                ? 'input error'
                                : 'input'
                            }
                            id="links_multi"
                            name="links_multi"
                            type="text"
                            placeholder="Ссылка на подобный реализованный проект"
                            onChange={handleChange}
                            value={values.links_multi}
                          />
                          {values.links_multi && (
                            <div
                              className="add-btn"
                              onClick={() => {
                                addLinksMulti(values.links_multi, (len) => {
                                  setFieldValue('links_multi', '')
                                  setFieldValue('links_hidden', len)
                                })
                              }}
                            >
                              {'+'}
                            </div>
                          )}
                          <span className="sendvote__error">
                            {errors.links_hidden &&
                              touched.links_hidden &&
                              errors.links_hidden}
                          </span>

                          {linksProject.length > 0 && (
                            <div className="file-text__multiply">
                              {linksProject.map((link) => (
                                <div
                                  key={link}
                                  className="file-text__multiply_item"
                                >
                                  <div className="file-text__multiply_text">
                                    {link}
                                  </div>
                                  <img
                                    className="file-text__multiply_image"
                                    src={Cross}
                                    alt=""
                                    onClick={() => {
                                      deleteLinksMulti(link)
                                    }}
                                  ></img>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <hr />

                  {/* Достижения */}
                  <div className="sendvote__descr">
                    {params.nomination === 1
                      ? '5 главных достижений НКО за 2023 год'
                      : params.nomination === 2
                        ? '5 главных достижений или проблем, которые решил проект'
                        : params.nomination === 3
                          ? '5 главных достижений или проблем, которые решит идея'
                          : ''}
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <label htmlFor="achievements_1">
                        {params.nomination === 1
                          ? '5 главных достижений НКО за 2023 год* (максимум – 600 символов)'
                          : params.nomination === 2
                            ? '5 главных достижений или проблем, которые решил проект* (максимум – 600 символов)'
                            : params.nomination === 3
                              ? '5 главных достижений или проблем, которые решит идея* (максимум – 600 символов)'
                              : ''}
                      </label>
                      <Field
                        className={
                          errors.achievements_1 && touched.achievements_1
                            ? 'input error'
                            : 'input'
                        }
                        id="achievements_1"
                        name="achievements_1"
                        type="text"
                        placeholder="Достижение №1"
                        onChange={handleChange}
                        value={values.achievements_1}
                      />
                      <span className="sendvote__error">
                        {errors.achievements_1 &&
                          touched.achievements_1 &&
                          errors.achievements_1}
                      </span>
                    </div>
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <Field
                        className={
                          errors.achievements_2 && touched.achievements_2
                            ? 'input error'
                            : 'input'
                        }
                        id="achievements_2"
                        name="achievements_2"
                        type="text"
                        placeholder="Достижение №2"
                        onChange={handleChange}
                        value={values.achievements_2}
                      />
                      <span className="sendvote__error">
                        {errors.achievements_2 &&
                          touched.achievements_2 &&
                          errors.achievements_2}
                      </span>
                    </div>
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <Field
                        className={
                          errors.achievements_3 && touched.achievements_3
                            ? 'input error'
                            : 'input'
                        }
                        id="achievements_3"
                        name="achievements_3"
                        type="text"
                        placeholder="Достижение №3"
                        onChange={handleChange}
                        value={values.achievements_3}
                      />
                      <span className="sendvote__error">
                        {errors.achievements_3 &&
                          touched.achievements_3 &&
                          errors.achievements_3}
                      </span>
                    </div>
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <Field
                        className={
                          errors.achievements_4 && touched.achievements_4
                            ? 'input error'
                            : 'input'
                        }
                        id="achievements_4"
                        name="achievements_4"
                        type="text"
                        placeholder="Достижение №4"
                        onChange={handleChange}
                        value={values.achievements_4}
                      />
                      <span className="sendvote__error">
                        {errors.achievements_4 &&
                          touched.achievements_4 &&
                          errors.achievements_4}
                      </span>
                    </div>
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__input-block w-full">
                      <Field
                        className={
                          errors.achievements_5 && touched.achievements_5
                            ? 'input error'
                            : 'input'
                        }
                        id="achievements_5"
                        name="achievements_5"
                        type="text"
                        placeholder="Достижение №5"
                        onChange={handleChange}
                        value={values.achievements_5}
                      />
                      <span className="sendvote__error">
                        {errors.achievements_5 &&
                          touched.achievements_5 &&
                          errors.achievements_5}
                      </span>
                    </div>
                  </div>
                  {params.nomination === 1 && (
                    <>
                      {/* Цели и задачи */}
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block w-full">
                          <label htmlFor="description">
                            {params.nomination === 1
                              ? 'Цели и задачи НКО*'
                              : params.nomination === 2
                                ? 'Краткое описание проекта (отображается при голосовании)*'
                                : params.nomination === 3
                                  ? 'Описание идеи (отображается при голосовании)*'
                                  : ''}
                          </label>
                          <Field
                            id="description"
                            name="description"
                            type="textarea"
                            component="textarea"
                            placeholder={
                              params.nomination === 1
                                ? 'Цели и задачи НКО'
                                : params.nomination === 2
                                  ? 'Краткое описание проекта'
                                  : params.nomination === 3
                                    ? 'Описание идеи'
                                    : ''
                            }
                            onChange={handleChange}
                            value={values.description}
                          />
                          <span className="sendvote__error">
                            {errors.description &&
                              touched.description &&
                              errors.description}
                          </span>
                        </div>
                      </div>
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block w-full">
                          <label htmlFor="company_nko_resource_profit">
                            Ресурсная обеспеченность НКО (кол-во привлеченных
                            ресурсов в организацию на уставную деятельность)*
                          </label>
                          <Field
                            className={
                              errors.company_nko_resource_profit &&
                                touched.company_nko_resource_profit
                                ? 'input error'
                                : 'input'
                            }
                            id="company_nko_resource_profit"
                            name="company_nko_resource_profit"
                            type="text"
                            placeholder="Ресурсная обеспеченность НКО"
                            onChange={handleChange}
                            value={values.company_nko_resource_profit}
                          />
                          <span className="sendvote__error">
                            {errors.company_nko_resource_profit &&
                              touched.company_nko_resource_profit &&
                              errors.company_nko_resource_profit}
                          </span>
                        </div>
                      </div>
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block file w-full">
                          <label htmlFor="file_smeta">
                            Планируемые затраты на развитие НКО в 2024 году в
                            случае победы (смета и др.)*
                          </label>
                          <div className="file-text__wrap">
                            <input
                              className={
                                errors.file_smeta && touched.file_smeta
                                  ? 'file error'
                                  : 'file'
                              }
                              id="file_smeta"
                              name="file_smeta"
                              accept="image/png,image/jpeg,image/jpg,.doc,.docx,.pdf,.xls,.xlsx,.odt,.ods"
                              type="file"
                              onChange={(e) => {
                                if (
                                  e.target.files[0].size /
                                  1000 >
                                  5000
                                ) {
                                  return errorRegisterToast(
                                    'Прикрепите файл размером меньше 5мб'
                                  );
                                }
                                setFieldValue(
                                  'file_smeta',
                                  e.currentTarget.files[0],
                                )
                                setFieldValue(
                                  'file_smeta_prev',
                                  e.currentTarget.files[0].name,
                                )
                              }}
                            />
                            <span className="file-text__pseudo">
                              <img src={Attach} alt=""></img>
                            </span>
                            <span
                              className={
                                'file-text__value ' +
                                (values.file_smeta ? 'active' : '')
                              }
                            >
                              {values.file_smeta_prev
                                ? values.file_smeta_prev
                                : 'Планируемые затраты на развитие НКО в 2024 году в случае победы (смета и др.)'}
                            </span>
                          </div>
                          <span className="sendvote__error">
                            {errors.file_smeta &&
                              touched.file_smeta &&
                              errors.file_smeta}
                          </span>
                          <div className="sendvote__input-group download">
                          </div>
                        </div>
                      </div>
                      <div className="sendvote__checkbox-block" style={{ marginTop: '20px' }}>
                        <a href={Order} className="sendvote__download">
                          <img src={Attach} alt="" />
                          <p>
                            Скачать образец шаблона <u>Сметный расчёт.docx</u>
                          </p>
                        </a>
                      </div>
                    </>
                  )}
                  <hr />

                  {params.nomination === 1 && (
                    <>
                      {/* Команда */}
                      <div className="sendvote__descr">Команда</div>
                      <div className="sendvote__input-group double">
                        <div className="sendvote__input-block">
                          <label htmlFor="company_nko_count_peaple">
                            Количество волонтеров*
                          </label>
                          <Field
                            className={
                              errors.company_nko_count_peaple &&
                                touched.company_nko_count_peaple
                                ? 'input error'
                                : 'input'
                            }
                            id="company_nko_count_peaple"
                            name="company_nko_count_peaple"
                            type="number"
                            placeholder="Количество волонтеров"
                            onChange={handleChange}
                            value={values.company_nko_count_peaple}
                          />
                          <span className="sendvote__error">
                            {errors.company_nko_count_peaple &&
                              touched.company_nko_count_peaple &&
                              errors.company_nko_count_peaple}
                          </span>
                        </div>
                        <div className="sendvote__input-block">
                          <label htmlFor="company_nko_peaple_achievements">
                            Их опыт и достижения*
                          </label>
                          <Field
                            className={
                              errors.company_nko_peaple_achievements &&
                                touched.company_nko_peaple_achievements
                                ? 'input error'
                                : 'input'
                            }
                            id="company_nko_peaple_achievements"
                            name="company_nko_peaple_achievements"
                            type="text"
                            placeholder="Их опыт и достижения"
                            onChange={handleChange}
                            value={values.company_nko_peaple_achievements}
                          />
                          <span className="sendvote__error">
                            {errors.company_nko_peaple_achievements &&
                              touched.company_nko_peaple_achievements &&
                              errors.company_nko_peaple_achievements}
                          </span>
                        </div>
                      </div>
                      <div className="sendvote__input-group double">
                        <div className="sendvote__input-block">
                          <label htmlFor="company_nko_count_employee">
                            Количество сотрудников НКО*
                          </label>
                          <Field
                            className={
                              errors.company_nko_count_employee &&
                                touched.company_nko_count_employee
                                ? 'input error'
                                : 'input'
                            }
                            id="company_nko_count_employee"
                            name="company_nko_count_employee"
                            type="number"
                            placeholder="Количество сотрудников НКО"
                            onChange={handleChange}
                            value={values.company_nko_count_employee}
                          />
                          <span className="sendvote__error">
                            {errors.company_nko_count_employee &&
                              touched.company_nko_count_employee &&
                              errors.company_nko_count_employee}
                          </span>
                        </div>
                        <div className="sendvote__input-block">
                          <label htmlFor="company_nko_employee_achievements">
                            Их опыт и достижения*
                          </label>
                          <Field
                            className={
                              errors.company_nko_employee_achievements &&
                                touched.company_nko_employee_achievements
                                ? 'input error'
                                : 'input'
                            }
                            id="company_nko_employee_achievements"
                            name="company_nko_employee_achievements"
                            type="text"
                            placeholder="Их опыт и достижения"
                            onChange={handleChange}
                            value={values.company_nko_employee_achievements}
                          />
                          <span className="sendvote__error">
                            {errors.company_nko_employee_achievements &&
                              touched.company_nko_employee_achievements &&
                              errors.company_nko_employee_achievements}
                          </span>
                        </div>
                      </div>

                      <hr />
                    </>
                  )}

                  {/* Документы */}
                  <div className="sendvote__descr">Документы</div>
                  {params.nomination !== 1 && (
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block file w-full">
                        <label htmlFor="file_smeta">
                          {params.nomination === 2
                            ? 'Планируемые затраты на развитие проекта в 2024 году в случае победы (смета и др.)*'
                            : params.nomination === 3
                              ? 'Планируемые затраты на развитие идеи в 2024 году в случае победы (смета и др.)*'
                              : ''}
                        </label>
                        <div className="file-text__wrap">
                          <input
                            className={
                              errors.file_smeta && touched.file_smeta
                                ? 'file error'
                                : 'file'
                            }
                            id="file_smeta"
                            name="file_smeta"
                            accept="image/png,image/jpeg,image/jpg,.doc,.docx,.pdf,.xls,.xlsx,.odt,.ods"
                            type="file"
                            onChange={(e) => {
                              if (
                                e.target.files[0].size /
                                1000 >
                                5000
                              ) {
                                return errorRegisterToast(
                                  'Прикрепите файл размером меньше 5мб'
                                );
                              }
                              setFieldValue(
                                'file_smeta',
                                e.currentTarget.files[0],
                              )
                              setFieldValue(
                                'file_smeta_prev',
                                e.currentTarget.files[0].name,
                              )
                            }}
                          />
                          <span className="file-text__pseudo">
                            <img src={Attach} alt=""></img>
                          </span>
                          <span
                            className={
                              'file-text__value ' +
                              (values.file_smeta ? 'active' : '')
                            }
                          >
                            {values.file_smeta_prev
                              ? values.file_smeta_prev
                              : params.nomination === 2
                                ? 'Планируемые затраты на развитие проекта в 2024 году в случае победы (смета и др.)'
                                : params.nomination === 3
                                  ? 'Планируемые затраты на развитие идеи в 2024 году в случае победы (смета и др.)'
                                  : ''}
                          </span>
                        </div>
                        <div className="sendvote__checkbox-block" style={{ marginTop: '20px' }}>
                          <a href={Order} className="sendvote__download">
                            <img src={Attach} alt="" />
                            <p>
                              Скачать образец шаблона <u>Сметный расчёт.docx</u>
                            </p>
                          </a>
                        </div>
                        <span className="sendvote__error">
                          {errors.file_smeta &&
                            touched.file_smeta &&
                            errors.file_smeta}
                        </span>
                      </div>
                    </div>
                  )}
                  {(params.nomination === 1 ||
                    values.is_individual === false) && (
                      <div className="sendvote__input-group">
                        <div className="sendvote__input-block file w-full multiply">
                          <label htmlFor="company_file_ustavnie_multi">
                            Учредительные документы*
                          </label>
                          <div className="file-text__wrap">
                            <input
                              className={
                                errors.company_file_ustavnie_multi &&
                                  touched.company_file_ustavnie_multi
                                  ? 'file error'
                                  : 'file'
                              }
                              id="company_file_ustavnie_multi"
                              name="company_file_ustavnie_multi"
                              accept="image/png,image/jpeg,image/jpg,.doc,.docx,.pdf,.xls,.xlsx,.odt,.ods"
                              type="file"
                              onChange={(e) => {
                                if (
                                  e.target.files[0].size /
                                  1000 >
                                  5000
                                ) {
                                  return errorRegisterToast(
                                    'Прикрепите файл размером меньше 5мб'
                                  );
                                }
                                addUstavFiles(e.currentTarget.files, (len) => {
                                  setFieldValue(
                                    'company_file_ustavnie_hidden',
                                    len,
                                  )
                                })
                              }}
                              multiple
                            />
                            <div className="sendvote__loadico">
                              <img src={Loadimage} alt=""></img>
                            </div>
                            <span className="file-text__value"> </span>
                          </div>
                          {ustavFiles.length > 0 && (
                            <div className="file-text__multiply">
                              {ustavFiles.map((file) => (
                                <div
                                  key={file.size + file.name}
                                  className="file-text__multiply_item"
                                >
                                  <div className="file-text__multiply_text">
                                    {file.name}
                                  </div>
                                  <img
                                    className="file-text__multiply_image"
                                    src={Cross}
                                    alt=""
                                    onClick={() => {
                                      deleteUstavFile(file.name)
                                    }}
                                  ></img>
                                </div>
                              ))}
                            </div>
                          )}
                          <span className="sendvote__error">
                            {errors.company_file_ustavnie_multi &&
                              touched.company_file_ustavnie_multi &&
                              errors.company_file_ustavnie_multi}
                          </span>
                        </div>
                      </div>
                    )}
                  {params.nomination !== 1 && (
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block file w-full multiply">
                        <label htmlFor="files_multi">
                          Дополнительные материалы (письма поддержки, публикации
                          в СМИ, презентации и т.д. в pdf-формате)
                        </label>
                        <div className="file-text__wrap">
                          <input
                            className={
                              errors.files_multi && touched.files_multi
                                ? 'file error'
                                : 'file'
                            }
                            id="files_multi"
                            name="files_multi"
                            accept=".pdf"
                            type="file"
                            onChange={(e) => {
                              if (
                                e.target.files[0].size /
                                1000 >
                                5000
                              ) {
                                return errorRegisterToast(
                                  'Прикрепите файл размером меньше 5мб'
                                );
                              }
                              addAttachFiles(e.currentTarget.files)
                            }}
                            multiple
                          />
                          <div className="sendvote__loadico">
                            <img src={Loadimage} alt=""></img>
                          </div>
                          <span className="file-text__value"> </span>
                        </div>
                        {attachFiles.length > 0 && (
                          <div className="file-text__multiply">
                            {attachFiles.map((file) => (
                              <div
                                key={file.size + file.name}
                                className="file-text__multiply_item"
                              >
                                <div className="file-text__multiply_text">
                                  {file.name}
                                </div>
                                <img
                                  className="file-text__multiply_image"
                                  src={Cross}
                                  alt=""
                                  onClick={() => {
                                    deleteAttachFile(file.name)
                                  }}
                                ></img>
                              </div>
                            ))}
                          </div>
                        )}
                        <span className="sendvote__error">
                          {errors.files_multi &&
                            touched.files_multi &&
                            errors.files_multi}
                        </span>
                      </div>
                    </div>
                  )}
                  {(params.nomination === 1 ||
                    values.is_individual === false) && (
                      <>
                        <div className="sendvote__input-group">
                          <div className="sendvote__input-block w-full">
                            <label htmlFor="company_created_date">
                              Дата создания организации*
                            </label>
                            <Field
                              className={
                                !!errors.company_created_date
                                  ? 'input datepicker error'
                                  : 'input datepicker'
                              }
                              id="company_created_date"
                              component={DateInput}
                              clearIcon={null}
                              calendarIcon={null}
                              monthPlaceholder={'ММ'}
                              dayPlaceholder={'ДД'}
                              yearPlaceholder={'ГГГГ'}
                              onChange={(e) => {
                                setDate(e)
                                setFieldValue(
                                  'company_created_date',
                                  formatDate2(e),
                                )
                                setFieldValue(
                                  'selected_date',
                                  (e),
                                )
                              }}
                              value={date}
                              name="company_created_date"
                              type="text"
                              placeholder="ДД.ММ.ГГГГ"
                            />
                            <span className="sendvote__error">
                              {errors.company_created_date &&
                                touched.company_created_date &&
                                errors.company_created_date}
                            </span>
                          </div>
                        </div>
                        <div className="sendvote__input-group">
                          <div className="sendvote__input-block file w-full">
                            <label htmlFor="company_file_zero_debt">
                              Справка об отсутствии задолженности организации*
                            </label>
                            <div className="file-text__wrap">
                              <input
                                className={
                                  errors.company_file_zero_debt &&
                                    touched.company_file_zero_debt
                                    ? 'file error'
                                    : 'file'
                                }
                                id="company_file_zero_debt"
                                name="company_file_zero_debt"
                                accept="image/png,image/jpeg,image/jpg,.doc,.docx,.pdf,.xls,.xlsx,.odt,.ods"
                                type="file"
                                onChange={(e) => {
                                  if (
                                    e.target.files[0].size /
                                    1000 >
                                    5000
                                  ) {
                                    return errorRegisterToast(
                                      'Прикрепите файл размером меньше 5мб'
                                    );
                                  }
                                  setFieldValue(
                                    'company_file_zero_debt',
                                    e.currentTarget.files[0],
                                  )
                                  setFieldValue(
                                    'company_file_zero_debt_prev',
                                    e.currentTarget.files[0].name,
                                  )
                                }}
                              />
                              <span className="file-text__pseudo">
                                <img src={Attach} alt=""></img>
                              </span>
                              <span
                                className={
                                  'file-text__value ' +
                                  (values.company_file_zero_debt ? 'active' : '')
                                }
                              >
                                {values.company_file_zero_debt_prev
                                  ? values.company_file_zero_debt_prev
                                  : 'Справка об отсутствии задолженности организации'}
                              </span>
                            </div>
                            <span className="sendvote__error">
                              {errors.company_file_zero_debt &&
                                touched.company_file_zero_debt &&
                                errors.company_file_zero_debt}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  {params.nomination === 1 && (
                    <div className="sendvote__input-group">
                      <div className="sendvote__input-block file w-full multiply">
                        <label htmlFor="files_multi">
                          Дополнительные материалы (письма поддержки, публикации
                          в СМИ, презентации и т.д. в pdf-формате)
                        </label>
                        <div className="file-text__wrap">
                          <input
                            className={
                              errors.files_multi && touched.files_multi
                                ? 'file error'
                                : 'file'
                            }
                            id="files_multi"
                            name="files_multi"
                            accept=".pdf"
                            type="file"
                            onChange={(e) => {
                              if (
                                e.target.files[0].size /
                                1000 >
                                5000
                              ) {
                                return errorRegisterToast(
                                  'Прикрепите файл размером меньше 5мб'
                                );
                              }
                              addAttachFiles(e.currentTarget.files)
                            }}
                            multiple
                          />
                          <div className="sendvote__loadico">
                            <img src={Loadimage} alt=""></img>
                          </div>
                          <span className="file-text__value"> </span>
                        </div>
                        {attachFiles.length > 0 && (
                          <div className="file-text__multiply">
                            {attachFiles.map((file) => (
                              <div
                                key={file.size + file.name}
                                className="file-text__multiply_item"
                              >
                                <div className="file-text__multiply_text">
                                  {file.name}
                                </div>
                                <img
                                  className="file-text__multiply_image"
                                  src={Cross}
                                  alt=""
                                  onClick={() => {
                                    deleteAttachFile(file.name)
                                  }}
                                ></img>
                              </div>
                            ))}
                          </div>
                        )}
                        <span className="sendvote__error">
                          {errors.files_multi &&
                            touched.files_multi &&
                            errors.files_multi}
                        </span>
                      </div>
                    </div>
                  )}

                  <hr />

                  {(params.nomination === 1 ||
                    values.is_individual === false) && (
                      <>
                        <div className="sendvote__descr">
                          Подтверждение подачи заявки
                        </div>
                        <div className="sendvote__input-group">
                          <div className="sendvote__input-block file w-full">
                            <label htmlFor="company_file_appruve">
                              Прикрепите скан-копию в формате PDF*
                            </label>
                            <div className="file-text__wrap">
                              <input
                                className={
                                  errors.company_file_appruve &&
                                    touched.company_file_appruve
                                    ? 'file error'
                                    : 'file'
                                }
                                id="company_file_appruve"
                                name="company_file_appruve"
                                accept=".pdf"
                                type="file"
                                onChange={(e) => {
                                  if (
                                    e.target.files[0].size /
                                    1000 >
                                    5000
                                  ) {
                                    return errorRegisterToast(
                                      'Прикрепите файл размером меньше 5мб'
                                    );
                                  }
                                  setFieldValue(
                                    'company_file_appruve',
                                    e.currentTarget.files[0],
                                  )
                                  setFieldValue(
                                    'company_file_appruve_prev',
                                    e.currentTarget.files[0].name,
                                  )
                                }}
                              />
                              <span className="file-text__pseudo">
                                <img src={Attach} alt=""></img>
                              </span>
                              <span
                                className={
                                  'file-text__value ' +
                                  (values.company_file_appruve ? 'active' : '')
                                }
                              >
                                {values.company_file_appruve_prev
                                  ? values.company_file_appruve_prev
                                  : 'Прикрепите скан-копию в формате PDF'}
                              </span>
                            </div>
                            <span className="sendvote__error">
                              {errors.company_file_appruve &&
                                touched.company_file_appruve &&
                                errors.company_file_appruve}
                            </span>
                          </div>
                        </div>
                        <div className="sendvote__input-group download">
                          <div className="sendvote__checkbox-block">
                            <a href={AcceptFile} className="sendvote__download">
                              <img src={Attach} alt="" />
                              <p>
                                Скачать шаблон <u>формы подтверждения заявки</u>
                              </p>
                            </a>
                          </div>
                        </div>
                        <div className="sendvote__input-group">
                          <div className="sendvote__checkbox-block">
                            <div className="sendvote__text">
                              <p>
                                - Для завершения подготовки заявки необходимо
                                скачать форму подтверждения заявки;
                              </p>

                              <p>
                                - Руководителю организации поставить личную
                                подпись и собственноручно написать свои фамилию,
                                имя и отчество, а также поставить печать
                                организации (при наличии печати у организации);
                              </p>

                              <p>
                                - Отсканировать подписанный документ, сохранить
                                скан-копию в формате PDF;
                              </p>

                              <p>- Прикрепить файл.</p>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Согласия */}
                  <div className="sendvote__input-group">
                    <div className="sendvote__checkbox-block">
                      <label htmlFor="personal_data_confirmation">
                        <Field
                          id="personal_data_confirmation"
                          name="personal_data_confirmation"
                          type="checkbox"
                          onChange={handleChange}
                        />
                        <span>
                          Я даю{' '}
                          <a
                            target={'_blank'}
                            rel="noreferrer"
                            href="https://er.ru/upages/personal"
                          >
                            согласие на обработку{' '}
                          </a>
                          моих персональных данных в соответствии ФЗ №152-ФЗ «О
                          персональных данных» и на
                          <a
                            target={'_blank'}
                            rel="noreferrer"
                            href="https://er.ru/upages/privacy-policy"
                          >
                            {' '}
                            Политику конфиденциальности
                          </a>
                        </span>
                      </label>
                      <span className="sendvote__error">
                        {errors.personal_data_confirmation &&
                          touched.personal_data_confirmation &&
                          errors.personal_data_confirmation}
                      </span>
                    </div>
                  </div>
                  <div className="sendvote__input-group">
                    <div className="sendvote__checkbox-block">
                      <label htmlFor="agree">
                        <Field
                          id="agree"
                          name="agree"
                          type="checkbox"
                          onChange={handleChange}
                        />
                        <span>Согласие на участие в Конкурсе</span>
                      </label>
                      <span className="sendvote__error">
                        {errors.agree && touched.agree && errors.agree}
                      </span>
                    </div>
                  </div>

                  <div className="sendvote__input-button to-right">
                    <button
                      className="sendvote__btn-submit button"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading && <Loader />}
                      <span style={{ opacity: isLoading ? 0 : 1 }}>
                        Отправить
                      </span>
                    </button>
                  </div>
                  <ToastContainer />
                </Form>
              )}
            </>
          )
        }}
      </Formik>
      <ErrorModal
        isOpen={errorSend}
        setIsOpen={setErrorSend}
        errors={errorSend}
      />
    </>
  )
}
