import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

export const ErrorModal = ({ isOpen, setIsOpen, errors }) => {
  const clearModalState = () => {
    setIsOpen([])
  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgb(255,255,255,0.5)',
      display: 'flex',
      alignItems: 'center',
      zIndex: 9,
    },
    content: {
      position: 'unset',
      backgroundColor: '#2173BA',
      height: 'fit-content',
      margin: '0 auto',
      top: '0',
      bottom: '0',
      transform: 'unset',
      padding: '25px',
      maxWidth: '520px',
      width: '100%',
    },
  }

  const closeModal = () => clearModalState()

  return (
    <Modal
      isOpen={Object.keys(isOpen).length > 0}
      onRequestClose={closeModal}
      className={'submit_modal'}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={false}
    >
      <div>
        <h2>Ошибка отправки</h2>
        <hr />
        <div className="submit_modal_text_wrap">
          <div>
            {Object.keys(errors).map((index) => {
              return errors[index].map((item) => {
                return <p key={item}>- {item}</p>
              })
            })}
          </div>
        </div>
        <div className="submit_modal_btn_wrap">
          <button
            onClick={() => {
              closeModal()
            }}
            className="main__btn button modal__btn modal__btn-red"
          >
            Закрыть
          </button>
        </div>
      </div>
    </Modal>
  )
}
