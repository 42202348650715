// import { errorRegisterToast, errorToast } from "components/notifications";
import { setYear } from "date-fns"
export const validationPhone = (values) => {
  const errors = {}
  if (!values.phone || values.phone === true) {
    errors.phone = 'Обязательное поле'
  }
  if (values.phone && values.phone.length < 18) {
    errors.phone = 'Не правильный формат телефона'
  }
  if (!values.personal_data_confirmation) {
    errors.personal_data_confirmation = 'Обязательное поле'
  }
  return errors
}

export const validationSms = (values) => {
  const errors = {}
  if (!values.sms) {
    errors.sms = 'Обязательное поле'
  }

  if (values.sms && values.sms.length < 6) {
    errors.sms = 'Обязательное поле'
  }

  return errors
}

export const validationCallback = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Обязательное поле'
  }
  if (!values.mail) {
    errors.mail = 'Обязательное поле'
  }
  if (
    values.mail &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mail)
  ) {
    errors.mail = 'Неверный адрес электронной почты'
  }
  if (!values.comment.length > 0) {
    errors.comment = 'Обязательное поле'
  }

  return errors
}

export const validationProfile = (values) => {
  const errors = {}
  const text = /^[а-яА-ЯёЁ -]+$/
  if (!values.name) {
    errors.name = 'Обязательное поле'
  }
  if (values.name && !text.test(values.name)) {
    errors.name = 'Только русские буквы'
  }
  if (!values.surname) {
    errors.surname = 'Обязательное поле'
  }
  if (values.surname && !text.test(values.surname)) {
    errors.surname = 'Только русские буквы'
  }
  if (!values.birthday) {
    errors.birthday = 'Обязательное поле'
  }
  if (values.birthday && values.birthday === '') {
    errors.birthday = 'Обязательное поле'
  }
  if (!values.email) {
    errors.email = 'Обязательное поле'
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Не верный адрес электронной почты'
  }

  if (!values.city) {
    errors.city = 'Обязательное поле'
  }

  if (!values.region_id) {
    errors.region_id = 'Обязательное поле'
  }
  if (!values.district_id) {
    errors.district_id = 'Обязательное поле'
  }
  if (!values.street) {
    errors.street = 'Обязательное поле'
  }
  if (!values.house) {
    errors.house = 'Обязательное поле'
  }
  if (!values.personal_data_confirmation) {
    errors.personal_data_confirmation = 'Обязательное поле'
  }
  return errors
}

export const validationProfile2 = (values) => {
  const errors = {}
  const text = /^[а-яА-ЯёЁ -]+$/
  if (!values.name) {
    errors.name = 'Обязательное поле'
  }
  if (values.name && !text.test(values.name)) {
    errors.name = 'Только русские буквы'
  }
  if (!values.surname) {
    errors.surname = 'Обязательное поле'
  }
  if (values.surname && !text.test(values.surname)) {
    errors.surname = 'Только русские буквы'
  }
  if (values.patronymic && !text.test(values.patronymic)) {
    errors.patronymic = 'Только русские буквы'
  }
  if (!values.birthday) {
    errors.birthday = 'Обязательное поле'
  }
  if (!values.email) {
    errors.email = 'Обязательное поле'
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Не верный адрес электронной почты'
  }

  if (!values.address) {
    errors.address = 'Обязательное поле'
  }

  if (!values.region_id?.value) {
    errors.region_id = 'Обязательное поле'
  }
  if (!values.district_id) {
    errors.district_id = 'Обязательное поле'
  }

  return errors
}

export const validateNomination1 = (values) => {
  const errors = {}
  const needDate = setYear(new Date(), 2021).getTime()
    const currDate = values.selected_date ? values.selected_date.getTime() : ''
    const dateDiff = needDate > currDate


  if (!values.organisation_level_id) {
    errors.organisation_level_id = 'Обязательное поле'
  }
  if (!values.company_fio) {
    errors.company_fio = 'Обязательное поле'
  }
  if (!values.individual_position) {
    errors.individual_position = 'Обязательное поле'
  }
  if (
    !values.company_links_Site &&
    !values.company_links_SnOK &&
    !values.company_links_SnVK &&
    !values.company_links_Telegram
  ) {
    errors.company_links_Site = 'Обязательна хотя бы одна ссылка'
    errors.company_links_SnOK = 'Обязательна хотя бы одна ссылка'
    errors.company_links_SnVK = 'Обязательна хотя бы одна ссылка'
    errors.company_links_Telegram = 'Обязательна хотя бы одна ссылка'
  }
  if (
    !values.individual_links_Site &&
    !values.individual_links_SnOK &&
    !values.individual_links_VK &&
    !values.individual_links_Telegram
  ) {
    errors.individual_links_Site = 'Обязательна хотя бы одна ссылка'
    errors.individual_links_SnOK = 'Обязательна хотя бы одна ссылка'
    errors.individual_links_VK = 'Обязательна хотя бы одна ссылка'
    errors.individual_links_Telegram = 'Обязательна хотя бы одна ссылка'
  }
  if (!values.image_application) {
    errors.image_application = 'Обязательное поле'
  }
  if (!values.region_id) {
    errors.region_id = 'Обязательное поле'
  }
  if (!values.company_name) {
    errors.company_name = 'Обязательное поле'
  }
  if (!values.company_address) {
    errors.company_address = 'Обязательное поле'
  }
  if (!values.achievements_1) {
    errors.achievements_1 = 'Обязательное поле'
  }
  if (!values.achievements_2) {
    errors.achievements_2 = 'Обязательное поле'
  }
  if (!values.achievements_3) {
    errors.achievements_3 = 'Обязательное поле'
  }
  if (!values.achievements_4) {
    errors.achievements_4 = 'Обязательное поле'
  }
  if (!values.achievements_5) {
    errors.achievements_5 = 'Обязательное поле'
  }
  if (!values.description) {
    errors.description = 'Обязательное поле'
  }
  if (!values.company_nko_resource_profit) {
    errors.company_nko_resource_profit = 'Обязательное поле'
  }
  if (!values.file_smeta) {
    errors.file_smeta = 'Обязательное поле'
  }
  if (!values.company_nko_count_peaple) {
    errors.company_nko_count_peaple = 'Обязательное поле'
  }
  if (!values.company_nko_peaple_achievements) {
    errors.company_nko_peaple_achievements = 'Обязательное поле'
  }
  if (!values.company_nko_count_employee) {
    errors.company_nko_count_employee = 'Обязательное поле'
  }
  if (!values.company_nko_employee_achievements) {
    errors.company_nko_employee_achievements = 'Обязательное поле'
  }
  if (!values.company_file_ustavnie_hidden > 0) {
    errors.company_file_ustavnie_multi = 'Обязательное поле'
  }
  if (!dateDiff) {
    errors.company_created_date = 'организации должно быть не менее 2 лет'
  }
  if (!values.company_created_date) {
    errors.company_created_date = 'Обязательное поле'
  }

  if (!values.company_file_zero_debt) {
    errors.company_file_zero_debt = 'Обязательное поле'
  }
  if (!values.company_file_appruve) {
    errors.company_file_appruve = 'Обязательное поле'
  }

  if (!values.personal_data_confirmation) {
    errors.personal_data_confirmation = 'Обязательное поле'
  }
  if (!values.agree) {
    errors.agree = 'Обязательное поле'
  }

  if (values.achievements_1.length > 600) {
    errors.achievements_1 = 'Слишком длинная строка'
  }
  if (values.achievements_2.length > 600) {
    errors.achievements_2 = 'Слишком длинная строка'
  }
  if (values.achievements_3.length > 600) {
    errors.achievements_3 = 'Слишком длинная строка'
  }
  if (values.achievements_4.length > 600) {
    errors.achievements_4 = 'Слишком длинная строка'
  }
  if (values.achievements_5.length > 600) {
    errors.achievements_5 = 'Слишком длинная строка'
  }
  if (values.description.length > 1000) {
    errors.description = 'Слишком длинная строка'
  }

  return errors
}

export const validateNomination2 = (values) => {
  const errors = {}

  if (values.is_individual !== true && values.is_individual !== false) {
    errors.is_individual = 'Обязательное поле'
  }
  if (!values.organisation_level_id) {
    errors.organisation_level_id = 'Обязательное поле'
  }
  if (values.is_individual === false) {
    if (!values.individual_position) {
      errors.individual_position = 'Обязательное поле'
    }
    if (
      !values.company_links_Site &&
      !values.company_links_SnOK &&
      !values.company_links_SnVK &&
      !values.company_links_Telegram
    ) {
      errors.company_links_Site = 'Обязательна хотя бы одна ссылка'
      errors.company_links_SnOK = 'Обязательна хотя бы одна ссылка'
      errors.company_links_SnVK = 'Обязательна хотя бы одна ссылка'
      errors.company_links_Telegram = 'Обязательна хотя бы одна ссылка'
    }
    if (!values.company_name) {
      errors.company_name = 'Обязательное поле'
    }
    if (!values.company_address) {
      errors.company_address = 'Обязательное поле'
    }
    if (!values.company_file_ustavnie_hidden > 0) {
      errors.company_file_ustavnie_multi = 'Обязательное поле'
    }
    if (!values.company_created_date) {
      errors.company_created_date = 'Обязательное поле'
    }
    if (!values.company_file_zero_debt) {
      errors.company_file_zero_debt = 'Обязательное поле'
    }
    if (!values.company_file_appruve) {
      errors.company_file_appruve = 'Обязательное поле'
    }
  }

  if (!values.company_fio) {
    errors.company_fio = 'Обязательное поле'
  }
  if (
    !values.individual_links_Site &&
    !values.individual_links_SnOK &&
    !values.individual_links_VK &&
    !values.individual_links_Telegram
  ) {
    errors.individual_links_Site = 'Обязательна хотя бы одна ссылка'
    errors.individual_links_SnOK = 'Обязательна хотя бы одна ссылка'
    errors.individual_links_VK = 'Обязательна хотя бы одна ссылка'
    errors.individual_links_Telegram = 'Обязательна хотя бы одна ссылка'
  }
  if (!values.image_application) {
    errors.image_application = 'Обязательное поле'
  }
  if (!values.region_id) {
    errors.region_id = 'Обязательное поле'
  }
  if (!values.name) {
    errors.name = 'Обязательное поле'
  }
  if (!values.description) {
    errors.description = 'Обязательное поле'
  }
  if (!values.actual) {
    errors.actual = 'Обязательное поле'
  }
  if (
    !values.project_links_SMI &&
    !values.project_links_SnOK &&
    !values.project_links_SnVK &&
    !values.project_links_Telegram
  ) {
    errors.project_links_SMI = 'Обязательна хотя бы одна ссылка'
    errors.project_links_SnOK = 'Обязательна хотя бы одна ссылка'
    errors.project_links_SnVK = 'Обязательна хотя бы одна ссылка'
    errors.project_links_Telegram = 'Обязательна хотя бы одна ссылка'
  }
  if (!values.achievements_1) {
    errors.achievements_1 = 'Обязательное поле'
  }
  if (!values.achievements_2) {
    errors.achievements_2 = 'Обязательное поле'
  }
  if (!values.achievements_3) {
    errors.achievements_3 = 'Обязательное поле'
  }
  if (!values.achievements_4) {
    errors.achievements_4 = 'Обязательное поле'
  }
  if (!values.achievements_5) {
    errors.achievements_5 = 'Обязательное поле'
  }
  if (!values.file_smeta) {
    errors.file_smeta = 'Обязательное поле'
  }

  if (!values.personal_data_confirmation) {
    errors.personal_data_confirmation = 'Обязательное поле'
  }
  if (!values.agree) {
    errors.agree = 'Обязательное поле'
  }

  if (values.achievements_1.length > 600) {
    errors.achievements_1 = 'Слишком длинная строка'
  }
  if (values.achievements_2.length > 600) {
    errors.achievements_2 = 'Слишком длинная строка'
  }
  if (values.achievements_3.length > 600) {
    errors.achievements_3 = 'Слишком длинная строка'
  }
  if (values.achievements_4.length > 600) {
    errors.achievements_4 = 'Слишком длинная строка'
  }
  if (values.achievements_5.length > 600) {
    errors.achievements_5 = 'Слишком длинная строка'
  }
  if (values.description.length > 1000) {
    errors.description = 'Слишком длинная строка'
  }

  return errors
}

export const validateNomination3 = (values) => {
  const errors = {}

  if (values.is_individual !== true && values.is_individual !== false) {
    errors.is_individual = 'Обязательное поле'
  }
  if (!values.organisation_level_id) {
    errors.organisation_level_id = 'Обязательное поле'
  }
  if (values.is_individual === false) {
    if (!values.individual_position) {
      errors.individual_position = 'Обязательное поле'
    }
    if (
      !values.company_links_Site &&
      !values.company_links_SnOK &&
      !values.company_links_SnVK &&
      !values.company_links_Telegram
    ) {
      errors.company_links_Site = 'Обязательна хотя бы одна ссылка'
      errors.company_links_SnOK = 'Обязательна хотя бы одна ссылка'
      errors.company_links_SnVK = 'Обязательна хотя бы одна ссылка'
      errors.company_links_Telegram = 'Обязательна хотя бы одна ссылка'
    }
    if (!values.company_name) {
      errors.company_name = 'Обязательное поле'
    }
    if (!values.company_address) {
      errors.company_address = 'Обязательное поле'
    }
    if (!values.company_file_ustavnie_hidden > 0) {
      errors.company_file_ustavnie_multi = 'Обязательное поле'
    }
    if (!values.company_created_date) {
      errors.company_created_date = 'Обязательное поле'
    }
    if (!values.company_file_zero_debt) {
      errors.company_file_zero_debt = 'Обязательное поле'
    }
    if (!values.company_file_appruve) {
      errors.company_file_appruve = 'Обязательное поле'
    }
  }

  if (
    !values.individual_links_Site &&
    !values.individual_links_SnOK &&
    !values.individual_links_VK &&
    !values.individual_links_Telegram
  ) {
    errors.individual_links_Site = 'Обязательна хотя бы одна ссылка'
    errors.individual_links_SnOK = 'Обязательна хотя бы одна ссылка'
    errors.individual_links_VK = 'Обязательна хотя бы одна ссылка'
    errors.individual_links_Telegram = 'Обязательна хотя бы одна ссылка'
  }
  if (!values.company_fio) {
    errors.company_fio = 'Обязательное поле'
  }
  if (!values.image_application) {
    errors.image_application = 'Обязательное поле'
  }
  if (!values.region_id) {
    errors.region_id = 'Обязательное поле'
  }
  if (!values.name) {
    errors.name = 'Обязательное поле'
  }
  if (!values.description) {
    errors.description = 'Обязательное поле'
  }
  if (!values.actual) {
    errors.actual = 'Обязательное поле'
  }
  if (!values.achievements_1) {
    errors.achievements_1 = 'Обязательное поле'
  }
  if (!values.achievements_2) {
    errors.achievements_2 = 'Обязательное поле'
  }
  if (!values.achievements_3) {
    errors.achievements_3 = 'Обязательное поле'
  }
  if (!values.achievements_4) {
    errors.achievements_4 = 'Обязательное поле'
  }
  if (!values.achievements_5) {
    errors.achievements_5 = 'Обязательное поле'
  }
  if (!values.file_smeta) {
    errors.file_smeta = 'Обязательное поле'
  }

  if (!values.personal_data_confirmation) {
    errors.personal_data_confirmation = 'Обязательное поле'
  }
  if (!values.agree) {
    errors.agree = 'Обязательное поле'
  }

  if (values.achievements_1.length > 600) {
    errors.achievements_1 = 'Слишком длинная строка'
  }
  if (values.achievements_2.length > 600) {
    errors.achievements_2 = 'Слишком длинная строка'
  }
  if (values.achievements_3.length > 600) {
    errors.achievements_3 = 'Слишком длинная строка'
  }
  if (values.achievements_4.length > 600) {
    errors.achievements_4 = 'Слишком длинная строка'
  }
  if (values.achievements_5.length > 600) {
    errors.achievements_5 = 'Слишком длинная строка'
  }
  if (values.description.length > 1000) {
    errors.description = 'Слишком длинная строка'
  }
  console.log(errors)
  return errors
}

export const validateText = (value) => {
  let errorMessage
  if (!value) {
    errorMessage = 'Обязательное поле'
  }
  return errorMessage
}

export const validateObj = (value) => {
  let errorMessage
  if (!value || !value.name) {
    errorMessage = 'Обязательное поле'
  }
  return errorMessage
}
