import React from 'react'
import Modal from 'react-modal'
import Like from 'assets/images/svg/like-white.svg'

Modal.setAppElement('#root')

export const SuccessModal = ({ isOpen, setIsOpen, votesCount }) => {
  const clearModalState = () => {
    setIsOpen(false)
  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgb(255,255,255,0.5)',
      display: 'flex',
      alignItems: 'center',
      zIndex: 9,
    },
    content: {
      position: 'unset',
      backgroundColor: '#2173BA',
      height: 'fit-content',
      margin: '0 auto',
      top: '0',
      bottom: '0',
      transform: 'unset',
      padding: '25px',
      maxWidth: '520px',
      width: '100%',
    },
  }

  const closeModal = () => clearModalState()

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={'submit_modal'}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={false}
    >
      <div>
        <h2>Спасибо!</h2>
        <hr />
        <div className="submit_modal_text_wrap">
          <p>Ваш голос учтён</p>
          <div className="submit_modal_like">
            <img src={Like} alt="" />
            <span className="like_text">{votesCount + 1}</span>
          </div>
        </div>
        <div className="submit_modal_btn_wrap">
          <button
            onClick={() => {
              closeModal()
              window.location.reload()
            }}
            className="main__btn button modal__btn modal__btn-red"
          >
            Понятно
          </button>
        </div>
      </div>
    </Modal>
  )
}
