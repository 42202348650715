import { useState } from 'react'
import { PhoneStep } from '../../components/forms/login/phoneStep'
import { SmsStep } from '../../components/forms/login/smsStep'
import { ProfileStep } from '../../components/forms/login/profileStep'
import { Profile } from 'pages/profile'
import { useAuthState } from 'context'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { scroll } from 'components/utils'

export const Login = (params) => {
  const [step, setStep] = useState(0)
  const [phone, setPhone] = useState('')
  const [isNew, setIsnew] = useState(false)
  const [expiredIn, setExpiredIn] = useState(5)
  const [smsError, setSmsError] = useState(false)
  const { userDetails } = useAuthState()
  const history = useHistory()
  const voteRedir = params?.location?.query?.vote_redir || false

  const nextStep = () => {
    setStep(step + 1)
  }
  useEffect(() => {
    userDetails?.name && history.push('/profile')
  }, [userDetails, history])
  useEffect(() => {
    scroll('#root')
  }, [])

  switch (step) {
    case 0:
      return (
        <PhoneStep
          nextStep={nextStep}
          setIsnew={setIsnew}
          setPhone={setPhone}
          setStep={setStep}
          setSmsError={setSmsError}
          setExpiredIn={setExpiredIn}
        />
      )
    case 1:
      return (
        <SmsStep
          phone={phone}
          isNew={isNew}
          setIsnew={setIsnew}
          smsError={smsError}
          expired={Math.round((300 - expiredIn) / 60)}
          setStep={setStep}
          setExpired={setExpiredIn}
          nextStep={nextStep}
          voteRedir={voteRedir}
        />
      )
    case 2:
      return <ProfileStep phone={phone} voteRedir={voteRedir} />
    case 3:
      return <Profile />
    default:
  }
}
